import { injectable } from 'inversify';

import { enqueueSnackbar } from 'src/components/snackbar';

import { INotificationService } from './notification.interface';

// ----------------------------------------------------------------------

@injectable()
export class EnqueueSnackbarService implements INotificationService {
  // eslint-disable-next-line @typescript-eslint/no-useless-constructor
  constructor() {
    //
  }

  // eslint-disable-next-line class-methods-use-this
  show: INotificationService['show'] = (message, options) => {
    try {
      const snackbarKey = enqueueSnackbar(message, {
        variant: options?.variant || 'info',
        autoHideDuration: options?.duration || 3000,
        anchorOrigin: options?.position || {
          vertical: options?.position?.vertical ?? 'top',
          horizontal: options?.position?.horizontal ?? 'right',
        },
      });
      return snackbarKey;
    } catch (error) {
      console.error(error);
      return -1;
    }
  };
}
