import { ResourceType } from 'src/services/api/types/enum-resources';

// ----------------------------------------------------------------------

export enum Params {
  ImageId = `${ResourceType.Image}_id`,
  FolderId = `${ResourceType.Folder}_id`,
  AiModelId = `${ResourceType.AiModel}_id`,
  ProjectId = `${ResourceType.Project}_id`,
  SnapshotId = `${ResourceType.Snapshot}_id`,
  SketchId = `${ResourceType.Sketch}_id`,
  ClassId = `${ResourceType.Class}_id`,
  ThreadId = `${ResourceType.Thread}_id`,
  CommentId = `${ResourceType.Comment}_id`,
  AnnotationId = `${ResourceType.Annotation}_id`,
  JobId = `${ResourceType.Job}_id`,
}

// ----------------------------------------------------------------------
