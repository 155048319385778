/* eslint-disable no-console */
/* eslint-disable class-methods-use-this */

import { Map } from 'ol';

import { store } from 'src/store';

import AnnotationClass from './annotation-class';
import AnnotationClassStoreManager from './annotation-class-store-manager';
import { generateRandomPolygons, convertGeoJsonToFeature } from './helpers/helpers';

/**
 * Manages Sandbox on the map for adding and removing annotations.
 */
export default class SandboxManager {
  public largeCount: number = 0;

  public mediumCount: number = 0;

  public smallCount: number = 0;

  private annotationClass: AnnotationClass | null = null;

  constructor(private map: Map) {}

  public setAnnotationClass(annotationClass: AnnotationClass | null) {
    this.annotationClass = annotationClass;
  }

  public generate() {
    if (!store.getState().canvas.sandboxConfig.enabled) {
      return;
    }

    if (!this.annotationClass) {
      console.warn('No annotation class assigned to sandbox manager');
      return;
    }

    console.time('Delete old performance annotations');

    const oldAnnotations = this.annotationClass.annotations.filter((annotation) =>
      annotation.feature.get('performance')
    );

    this.annotationClass.interactor.deleteAnnotation(oldAnnotations);

    console.timeEnd('Delete old performance annotations');

    console.time('Generate new annotations');

    const extent = this.map.getView()?.getViewStateAndExtent().extent;

    const features = generateRandomPolygons('lg', this.largeCount, extent)
      .concat(generateRandomPolygons('md', this.mediumCount, extent))
      .concat(generateRandomPolygons('sm', this.smallCount, extent))
      .map((polygon) => {
        const feature = convertGeoJsonToFeature(polygon);
        feature.set('performance', 1);
        return feature;
      });

    console.timeEnd('Generate new annotations');

    console.time('Add features');

    this.annotationClass.source.addFeatures(features);

    console.timeEnd('Add features');

    AnnotationClassStoreManager.updateStore();
  }
}
