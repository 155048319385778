export enum SentryTags {
  ERROR_TYPE = 'error.type',
  ERROR_CODE = 'error.code',
  ERROR_STATUS = 'error.status',
}

export enum SentryErrorType {
  API_ERROR = 'api_error',
}

export type SentryTagsType = {
  [SentryTags.ERROR_TYPE]: SentryErrorType;
  [SentryTags.ERROR_CODE]: string | undefined;
  [SentryTags.ERROR_STATUS]: number | undefined;
};
