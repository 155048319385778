import EventEmitter from 'eventemitter3';

import { EventName, EventPayloads } from './event-map';

// ----------------------------------------------------------------------

class EventHub extends EventEmitter<EventName> {
  private static instance: EventHub;

  private constructor() {
    super();
  }

  public static getInstance(): EventHub {
    if (!EventHub.instance) {
      EventHub.instance = new EventHub();
    }
    return EventHub.instance;
  }

  emit<T extends EventName>(event: T, payload: EventPayloads[T]): boolean {
    return super.emit(event, payload);
  }

  on<T extends EventName>(event: T, fn: (payload: EventPayloads[T]) => void): this {
    return super.on(event, fn);
  }

  // Here we can log triggered and received events by overwriting the emit and on methods

  // We can also add a method to remove all listeners for a specific event

  // We can also handle events globally, like in the browser with the window object (between tabs)

  // IMPORTANT: We can also handle errors globally for event triggering

  // Reference: For more and further practices and considerations, see https://blog.octalabs.com/pub-sub-pattern-in-react-96de463b7cf5#:~:text=Best%20Practices%20and%20Considerations%3A
}

export default EventHub.getInstance();
