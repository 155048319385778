import { createContext } from 'react';

import { EventPayloads } from 'src/services/event-hub';

// ----------------------------------------------------------------------

export type CanvasThreadsContextType = {
  evtClickThread: EventPayloads['canvas:threads:click'];
  evtHoverThread: EventPayloads['canvas:threads:hover'];
  evtClickMap: EventPayloads['canvas:map:click'];
  threads: EventPayloads['canvas:threads:update-features']['threads'];
};

export const CanvasThreadsContext = createContext<CanvasThreadsContextType | null>(null);
