import { store } from 'src/store';
import ModeManager from 'src/services/canvas-tools/mode-manager';
import canvasEmitter from 'src/services/canvas/helpers/canvas-emitter';
import {
  setQuickActionsMenuProps,
  toggleTotalAnnotationsVisibility,
} from 'src/store/slices/slice-canvas';

import MapService from './map-service';
import { AnnotationClassStoreAction } from './types/types';

// ----------------------------------------------------------------------

const nonStoreActions = ['UPDATE_ANNOTATIONS', 'ACTIVATE_CLASS'];

// ----------------------------------------------------------------------

/**
 * StoreManager is responsible for managing actions related to annotations,
 * such as setting modes, updating annotations, and tracking changes.
 */
export default class AnnotationClassStoreManager {
  /**
   * A map of action types to their corresponding action creators.
   */
  protected static actionMap: {
    [key in AnnotationClassStoreAction['type']]: (payload: any) => any;
  } = {
    //
    UPDATE_ANNOTATIONS: (annotationClasses) =>
      canvasEmitter.emit('classes-updated', { annotationClasses }),
    ACTIVATE_CLASS: (activeClass) => canvasEmitter.emit('active-class', { activeClass }),
    //
    SET_MODE: (mode) => ModeManager.getInstance().setMode(mode),
    TOGGLE_TOTAL_ANNOT_VISIBILITY: toggleTotalAnnotationsVisibility,
    SET_QUICK_ACTIONS_MENU_PROPS: setQuickActionsMenuProps,
  };

  /**
   * Updates the store based on the specified action and payload.
   * @param action - The action object containing type and payload.
   */
  static updateStore(action?: AnnotationClassStoreAction) {
    // Handle the default value of the action if it was not passed
    if (
      action === undefined ||
      (action.type === 'UPDATE_ANNOTATIONS' && action.payload === undefined)
    ) {
      action = {
        type: 'UPDATE_ANNOTATIONS',
        payload: [...MapService.getInstance().annotationClasses],
      };
    }

    const actionCreator = AnnotationClassStoreManager.actionMap[action.type];

    if (nonStoreActions.includes(action.type)) {
      actionCreator(action.payload);
    } else {
      store.dispatch(actionCreator(action.payload));
    }
  }
}
