import { JWT as JWTType } from 'aws-amplify/auth';

import { PageType } from 'src/helpers/mapper-page/enum-pages';
import { DynamicComponent } from 'src/services/api/types/types-dynamic-components';

import { IUser } from 'src/types/types-user';

import { PostSignUpReq } from '../api/types/types-req-res';

// ----------------------------------------------------------------------

export type ActionMapType<M extends { [index: string]: any }> = {
  [Key in keyof M]: M[Key] extends undefined
    ? {
        type: Key;
      }
    : {
        type: Key;
        payload: M[Key];
      };
};

export type AuthUserType =
  | null
  | (IUser & {
      displayName: string;
      idToken: JWTType | undefined;
      accessToken: JWTType | undefined;
    });

export type AuthStateType = {
  loading: boolean;
  user: AuthUserType;
  allowedPages: DynamicComponent<PageType>[];
  navigationPages: DynamicComponent<PageType>[];
};

// ----------------------------------------------------------------------

export enum AuthMethodType {
  JWT = 'jwt',
  Amplify = 'amplify',
}

export type AmplifyContextType = {
  user: AuthStateType['user'];
  allowedPages: AuthStateType['allowedPages'];
  navigationPages: AuthStateType['navigationPages'];
  method: AuthMethodType;
  loading: boolean;
  authenticated: boolean;
  unauthenticated: boolean;
  login: (email: string, password: string) => Promise<void>;
  register: (args: SignUpInput) => Promise<void>;
  logout: () => Promise<void>;
};

// ----------------------------------------------------------------------

export type SignUpInput = {
  user: PostSignUpReq;
};

type CustomSignOutOptions = {
  emitEvent: boolean;
};

export type SignOutInput = {
  options?: Partial<CustomSignOutOptions>;
};
