import mitt, { Emitter } from 'mitt';
import { useMemo, useContext, createContext } from 'react';

import { MittEvents } from 'src/services/canvas/types/mitt-events';

// ----------------------------------------------------------------------

export interface MittContextType {
  emitter: Emitter<MittEvents>;
}

const emitter: Emitter<MittEvents> = mitt();

const MittContext = createContext<MittContextType>({ emitter });

export function MittProvider({ children }: any) {
  const MittContextProviderValue = useMemo(() => ({ emitter }), []);

  return <MittContext.Provider value={MittContextProviderValue}>{children}</MittContext.Provider>;
}

export default function useMitt(): MittContextType {
  return useContext(MittContext);
}
