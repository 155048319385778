import { StorageKey, StoragePayloads } from './storage-map';

// ----------------------------------------------------------------------

class LocalStorage {
  static setItem<T extends StorageKey>(key: T, value: StoragePayloads[T]): void {
    try {
      const serializedValue = typeof value === 'string' ? value : JSON.stringify(value);
      localStorage.setItem(key, serializedValue);
    } catch (error) {
      console.error(`Error setting localStorage item: ${key}`, error);
    }
  }

  static getItem<T extends StorageKey>(key: T): StoragePayloads[T] | undefined {
    try {
      const value = localStorage.getItem(key);
      if (value === null) return undefined;
      try {
        return JSON.parse(value) as StoragePayloads[T];
      } catch {
        return value as StoragePayloads[T];
      }
    } catch (error) {
      console.error(`Error getting localStorage item: ${key}`, error);
      return undefined;
    }
  }

  static removeItem(key: StorageKey): void {
    try {
      localStorage.removeItem(key);
    } catch (error) {
      console.error(`Error removing localStorage item: ${key}`, error);
    }
  }

  static clear(): void {
    localStorage.clear();
  }

  // Additional utility methods can be added here
}

export default LocalStorage;
