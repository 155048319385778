import { Params } from './enum-params';
import { RoutePlaceholder } from './enum-route-placeholders';

// ----------------------------------------------------------------------

export const mapperRoutePlaceholder: {
  [key in Params]: RoutePlaceholder;
} = {
  [Params.ImageId]: RoutePlaceholder.ImageId,
  [Params.FolderId]: RoutePlaceholder.FolderId,
  [Params.AiModelId]: RoutePlaceholder.AiModelId,
  [Params.ProjectId]: RoutePlaceholder.ProjectId,
  [Params.SnapshotId]: RoutePlaceholder.SnapshotId,
  [Params.SketchId]: RoutePlaceholder.SketchId,
  [Params.ClassId]: RoutePlaceholder.ClassId,
  [Params.ThreadId]: RoutePlaceholder.ThreadId,
  [Params.CommentId]: RoutePlaceholder.CommentId,
  [Params.AnnotationId]: RoutePlaceholder.AnnotationId,
  [Params.JobId]: RoutePlaceholder.JobId,
};
