import { store } from 'src/store';
import { PostCanvasSaveRes, PostCanvasSaveReq } from 'src/services/api/types/types-req-res';

import { HandleSaveChangesProps } from 'src/sections/annotations-manager/types';

import { ClassId } from './types/types';
import AnnotationClassLockManager from './annotation-class-lock-manager';

// ----------------------------------------------------------------------

/**
 * SyncManager is responsible for synchronizing changes to classes and annotations.
 * It interacts with a lock manager to ensure operations are atomic and uses the store to persist changes.
 */
export class AnnotationClassSyncManager {
  /**
   * Constructs a SyncManager instance.
   * @param classId - The ID of the class to be managed.
   * @param locker - The lock manager to coordinate access.
   */
  constructor(
    private classId: ClassId,
    private locker: AnnotationClassLockManager
  ) {}

  /**
   * Synchronizes the creation of a new class.
   * @param payload - The properties of the class to be created.
   * @returns The ID of the newly created class.
   */
  async syncCreatedClass(
    payload: PostCanvasSaveReq['create']['ai_project_snapshot_classes'][0]
  ): Promise<PostCanvasSaveRes['create']['ai_project_snapshot_classes'][0]['id']> {
    this.locker.setSyncing(true);
    this.locker.lock();

    const createdClasses = [{ ...payload }];

    const response = await this.saveChangesFunction({ createdClasses });

    const { id } = response.create.ai_project_snapshot_classes[0];

    this.classId = id;

    this.locker.unlock();
    this.locker.setSyncing(false);

    return id;
  }

  /**
   * Synchronizes the updates to an existing class.
   * @param payload - The updated properties of the class.
   */
  async syncUpdatedClass(
    payload: PostCanvasSaveReq['update']['ai_project_snapshot_classes'][0]['updates']
  ): Promise<void> {
    this.locker.setSyncing(true);

    const updatedClasses = [{ id: this.classId as number, updates: { ...payload } }];

    await this.saveChangesFunction({ updatedClasses });

    this.locker.setSyncing(false);
  }

  /**
   * Synchronizes the creation of a new annotation.
   * @param idx - The index of the annotation to be created.
   * @param payload - The payload of the annotation to be created.
   * @returns The ID of the newly created annotation.
   */
  async syncCreatedAnnotation(
    idx: number,
    payload: PostCanvasSaveReq['create']['annotations'][0]
  ): Promise<PostCanvasSaveRes['create']['annotations'][0]['id']> {
    this.locker.setSyncingAnnotation({ idx }, true);
    this.locker.lockAnnotation(idx);

    const createdAnnotations = [payload];

    const response = await this.saveChangesFunction({ createdAnnotations });

    this.locker.unlockAnnotation(idx);
    this.locker.setSyncingAnnotation({ idx }, false);

    return response.create.annotations[0].id;
  }

  /**
   * Synchronizes the updates to an existing annotation.
   * @param id - The ID of the annotation to be updated.
   * @param payload - The updated properties of the annotation.
   */
  async syncUpdatedAnnotation(
    id: number,
    payload: PostCanvasSaveReq['update']['annotations'][0]['updates']
  ): Promise<void> {
    this.locker.setSyncingAnnotation({ id }, true);

    const updatedAnnotations = [{ id, updates: payload }];

    await this.saveChangesFunction({ updatedAnnotations });

    this.locker.setSyncingAnnotation({ id }, false);
  }

  /**
   * Synchronizes the deletion of an annotation.
   * @param id - The ID of the annotation to be deleted.
   */
  async syncDeletedAnnotations(id: number | number[]): Promise<void> {
    const deletedAnnotations = Array.isArray(id) ? id.map((i) => ({ id: i })) : [{ id }];

    await this.saveChangesFunction({ deletedAnnotations });
  }

  /**
   * Internal method to save changes via the store.
   * @param payload - The payload containing the changes to be saved.
   * @returns The response from the saveChangesFunction (PostCanvasSaveRes).
   */
  // eslint-disable-next-line class-methods-use-this
  private async saveChangesFunction(payload: HandleSaveChangesProps): Promise<PostCanvasSaveRes> {
    return store.getState().pageCanvas.saveChangesFunction(payload);
  }
}
