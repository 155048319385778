import { useMemo } from 'react';

import { paths } from 'src/routes/paths';

import { useTranslate } from 'src/locales';
import { useAuthContext } from 'src/services/auth/hooks';

import Iconify from 'src/components/iconify';

// ----------------------------------------------------------------------

const icon = (name: string) => (
  // <SvgColor src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />
  // OR
  <Iconify icon={name} />
  // https://icon-sets.iconify.design/solar/
  // https://www.streamlinehq.com/icons
);

const ICONS = {
  folder: icon('octicon:file-directory-24'),
  project: icon('octicon:project-24'),
  aiModel: icon('octicon:ai-model-24'),
};

// ----------------------------------------------------------------------

export function useNavData() {
  const { t } = useTranslate();

  const { navigationPages } = useAuthContext();

  const data = useMemo(
    () => [
      {
        subheader: '',
        items: [
          {
            title: t('images'),
            path: paths.dashboard.folder.root.to(),
            icon: ICONS.folder,
            isDepthActiveLink: true,
            hidden: navigationPages.map((i) => i.id).includes(paths.dashboard.folder.root.id),
          },
          {
            title: t('ai_projects'),
            path: paths.dashboard.project.root.to(),
            icon: ICONS.project,
            isDepthActiveLink: true,
            hidden: navigationPages.map((i) => i.id).includes(paths.dashboard.project.root.id),
          },
          {
            title: t('ai_models.ai_models'),
            path: paths.dashboard.aiModel.root.to(),
            icon: ICONS.aiModel,
            isDepthActiveLink: true,
            hidden: navigationPages.map((i) => i.id).includes(paths.dashboard.aiModel.root.id),
          },
        ].filter((i) => i.hidden),
      },
    ],
    [navigationPages, t]
  );

  return data;
}
