export enum UserRoleType {
  User = 'user',
  Admin = 'admin',
}

export interface IUser {
  id: string;
  email: string;
  org_id: string;
  photo_url: string;
  last_name: string;
  first_name: string;
  role: UserRoleType;
}

export type UserAttributes = {
  sub: string;
  email: string;
  'custom:org_id': string;
  'custom:user_id': string;
};
