import Feature from 'ol/Feature';
import Point from 'ol/geom/Point';
import Heatmap from 'ol/layer/Heatmap';

// ----------------------------------------------------------------------

/**
 * HeatmapManager is responsible for managing the state and visibility of a heatmap layer.
 */
export default class AnnotationClassHeatmapManager {
  /**
   * The heatmap layer managed by this instance.
   */
  private heatmap?: Heatmap<Feature<Point>>;

  /**
   * The heatmap prev visibility status.
   */
  private prevVisibilityStatus = false;

  /**
   * Adds a heatmap layer to be managed.
   * @param heatmap - The Heatmap layer to be added.
   */
  addHeatmapLayer(heatmap: Heatmap<Feature<Point>>): void {
    this.heatmap = heatmap;
  }

  /**
   * Retrieves the managed heatmap layer.
   * @returns The managed heatmap layer, or undefined if not set.
   */
  getHeatmapLayer(): Heatmap<Feature<Point>> | undefined {
    return this.heatmap;
  }

  /**
   * Retrieves the current visibility state of the heatmap layer.
   * @returns The current visibility state, or false if the heatmap is not set.
   */
  getHeatmapVisibility(): boolean {
    return this.heatmap?.getVisible() ?? false;
  }

  /**
   * Sets the visibility of the heatmap layer.
   * @param isVisible - Boolean indicating whether the heatmap should be visible.
   */
  setHeatmapVisibility(isVisible: boolean): void {
    this.heatmap?.setVisible(isVisible);
  }

  /**
   * Handle the parent layer visibility changed.
   * @param isLayerVisible - Boolean indicating whether the layer to be visible.
   */
  handleLayerVisibilityChanged(isLayerVisible: boolean): void {
    if (isLayerVisible) {
      this.setHeatmapVisibility(isLayerVisible);
    }

    if (!isLayerVisible && this.heatmap && this.prevVisibilityStatus) {
      this.setHeatmapVisibility(true);
      this.prevVisibilityStatus = true;
    }
  }

  /**
   * Toggles the visibility of the heatmap layer.
   * @returns The new visibility state of the heatmap.
   */
  toggleHeatmapVisibility(): boolean {
    const isVisible = !this.getHeatmapVisibility();

    this.setHeatmapVisibility(isVisible);

    this.prevVisibilityStatus = isVisible;

    return isVisible;
  }
}
