import Style from 'ol/style/Style';

import { AnnotationStyle } from 'src/services/canvas/types/types-canvas';

import { getLockStyle } from './helpers/helpers';
import AnnotationClassBaseManager from './annotation-class-base-manager';
import AnnotationClassStoreManager from './annotation-class-store-manager';
import AnnotationClassAnnotationsManager from './annotation-class-annotations-manager';

// ----------------------------------------------------------------------

/**
 * LockManager is responsible for managing the lock state of annotations and annotation classes.
 * It extends the BaseAnnotationClassManager to utilize its store update capabilities.
 */
export default class AnnotationClassLockManager extends AnnotationClassBaseManager {
  /**
   * Indicates whether the LockManager is currently locked.
   */
  private locked: boolean = false;

  private syncing: boolean = false;

  /**
   * Constructs a LockManager instance.
   * @param annotationManager - The AnnotationManager instance to manage annotations.
   * @param style - The AnnotationStyle to apply when locking annotations.
   */
  constructor(
    private annotationManager: AnnotationClassAnnotationsManager,
    private style: AnnotationStyle
  ) {
    super();
  }

  /**
   * Sets the style to be used for locked annotations.
   * @param style - The new AnnotationStyle to apply.
   */
  set styles(style: AnnotationStyle) {
    this.style = style;
  }

  get isSyncing(): boolean {
    return this.syncing;
  }

  get isLocked(): boolean {
    return this.locked;
  }

  get lockingStyle(): Style {
    return getLockStyle(this.style);
  }

  /**
   * Locks the LockManager and updates the store.
   */
  lock() {
    this.locked = true;
    AnnotationClassStoreManager.updateStore();
  }

  /**
   * Unlocks the LockManager and updates the store.
   */
  unlock() {
    this.locked = false;
    AnnotationClassStoreManager.updateStore();
  }

  setSyncing(value: boolean) {
    this.syncing = value;
    AnnotationClassStoreManager.updateStore();
  }

  /**
   * Locks a specific annotation by index and updates its style.
   * @param idx - The index of the annotation to lock.
   */
  lockAnnotation(idx: number) {
    this.annotationManager.updateAnnotationByIndex(idx, { locked: true });
    this.annotationManager.annotations[idx].feature?.set('locked', true);

    this.annotationManager.annotations[idx].feature?.setStyle(this.lockingStyle);

    AnnotationClassStoreManager.updateStore();
  }

  /**
   * Unlocks a specific annotation by index and resets its style.
   * @param idx - The index of the annotation to unlock.
   */
  unlockAnnotation(idx: number) {
    this.annotationManager.updateAnnotationByIndex(idx, { locked: false });
    this.annotationManager.annotations[idx].feature?.set('locked', false);

    this.annotationManager.annotations[idx].feature?.setStyle(undefined);

    AnnotationClassStoreManager.updateStore();
  }

  setSyncingAnnotation(
    { idx, id }: { idx: number; id?: undefined } | { idx?: undefined; id: number },
    value: boolean
  ) {
    if (id) {
      idx = this.annotationManager.findAnnotationIndexByUuid(id);
    }

    if (idx !== undefined && idx !== -1) {
      this.annotationManager.updateAnnotationByIndex(idx, { syncing: value });
    }
    AnnotationClassStoreManager.updateStore();
  }
}
