import { Feature } from 'ol';
import { getCenter } from 'ol/extent';
import { Point, Polygon, Geometry } from 'ol/geom';

// ----------------------------------------------------------------------

export function convertPolygonFeaturesToCenterPoints(
  features: Feature<Geometry>[]
): Feature<Point>[] {
  return features
    .map((feature) => {
      const geometry = feature.getGeometry();
      if (geometry instanceof Polygon) {
        const extent = geometry.getExtent();
        const center = getCenter(extent);
        return new Feature({
          geometry: new Point(center),
          weight: feature.get('weight'),
        });
      }
      return null;
    })
    .filter((feature): feature is Feature<Point> => feature !== null);
}
