import { PageType } from 'src/helpers/mapper-page/enum-pages';
import { Params } from 'src/helpers/mapper-route-placeholder';
import { DynamicComponent } from 'src/services/api/types/types-dynamic-components';
import { mapperRoutePlaceholder } from 'src/helpers/mapper-route-placeholder/mapper-route-placeholder';

// ----------------------------------------------------------------------

export const parseActions = (config: DynamicComponent, dynamicParams: Record<Params, string>) => {
  // Deep clone to avoid mutating the original config
  const clonedConfig: DynamicComponent<PageType, any> = JSON.parse(JSON.stringify(config));

  Object.entries(clonedConfig.actions).forEach(([action, { url }]) => {
    const queryParams: Record<string, string> = {};

    Object.entries(dynamicParams).forEach(([paramKey, paramValue]) => {
      const placeholder = mapperRoutePlaceholder[paramKey as Params];

      // Either, replace the route-param with backend endpoint route-placeholder
      if (url.includes(placeholder)) url = url.replace(placeholder, paramValue);
      // Or, add the route-param as a query parameter
      else queryParams[paramKey] = paramValue;
    });

    // Append query parameters to the URL if there are any
    if (Object.keys(queryParams).length > 0) {
      const queryString = new URLSearchParams(queryParams).toString();
      url += (url.includes('?') ? '&' : '?') + queryString;
    }

    clonedConfig.actions[action].url = url;
  });

  return clonedConfig;
};
