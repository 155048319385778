import Box from '@mui/material/Box';

import { hideScroll } from 'src/theme/css';
import { CANVAS_LEFT_PANEL } from 'src/layouts/config-layout';

import Logo from 'src/components/logo';

// ----------------------------------------------------------------------

export default function Header() {
  return (
    <Box
      component='header'
      sx={{
        left: 0,
        position: 'absolute',
        flexShrink: { lg: 0 },
        width: { lg: CANVAS_LEFT_PANEL.W, xs: 0 },
        height: '100%',
        zIndex: (theme) => theme.zIndex.appBar,
      }}
    >
      <Box
        display='flex'
        sx={{
          pb: 2,
          height: 1,
          position: 'fixed',
          width: CANVAS_LEFT_PANEL.W,
          bgcolor: (theme) => theme.palette.background.default,
          ...hideScroll.x,
        }}
      >
        <Logo sx={{ mx: 'auto', my: 2 }} />
      </Box>
    </Box>
  );
}
