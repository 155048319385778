import { Annotation } from 'src/services/canvas/types/types-canvas';

import { AnnotationId } from './types/types';

// ----------------------------------------------------------------------

/**
 * AnnotationManager is responsible for managing a collection of annotations,
 * including adding, updating, finding, and deleting annotations.
 */
export default class AnnotationClassAnnotationsManager {
  private _annotations: Annotation[] = [];

  private annotationIndexMap: Map<AnnotationId, number> = new Map();

  private featureIndexMap: Map<AnnotationId, number> = new Map();

  /**
   * Constructs an AnnotationManager instance.
   * @param annotations - The initial list of annotations.
   */
  constructor(annotations: Annotation[] = []) {
    this._annotations = annotations;

    this.buildIndexMaps();
  }

  /**
   * Set the list of annotations.
   */
  private set annotations(value: Annotation[]) {
    this._annotations = value;

    this.buildIndexMaps();
  }

  /**
   * Gets the list of annotations.
   * @returns The list of annotations.
   */
  get annotations(): Annotation[] {
    return this._annotations;
  }

  get length(): number {
    return this._annotations.length;
  }

  /**
   * Build the annotationIndexMap and featureIndexMap for fast search.
   * @param index - The starting index to build, will be set in case delete annotation.
   */
  buildIndexMaps(index = 0): void {
    if (index === 0) {
      this.annotationIndexMap.clear();
      this.featureIndexMap.clear();
    }

    for (let i = index; i < this.length; i += 1) {
      this.annotationIndexMap.set(this._annotations[i].id, i);
      this.featureIndexMap.set(this._annotations[i].feature?.get('id'), i);
    }
  }

  /**
   * Adds a new annotation to the list.
   * @param annotation - The annotation to add.
   * @returns the inserted index
   */
  addAnnotation(annotation: Annotation): number {
    this._annotations.push(annotation);
    const index = this.length - 1;

    this.annotationIndexMap.set(annotation.id, index);
    this.featureIndexMap.set(annotation.feature?.get('id'), index);

    return index;
  }

  /**
   * Finds an annotation by its ID.
   * @param id - The ID of the annotation to find.
   * @returns The found annotation, or undefined if not found.
   */
  findAnnotationById(id: AnnotationId): Annotation | undefined {
    const index = this.annotationIndexMap.get(id);
    return index !== undefined ? this._annotations[index] : undefined;
  }

  /**
   * Finds an annotation by the ID of its feature.
   * @param featureId - The feature ID of the annotation to find.
   * @returns The found annotation, or undefined if not found.
   */
  findAnnotationByFeatureId(featureId: AnnotationId): Annotation | undefined {
    const index = this.featureIndexMap.get(featureId);
    return index !== undefined ? this._annotations[index] : undefined;
  }

  /**
   * Finds the index of an annotation by its ID.
   * @param id - The ID of the annotation to find.
   * @returns The index of the annotation, or -1 if not found.
   */
  findAnnotationIndexById(id: AnnotationId): number {
    return this.annotationIndexMap.get(id) ?? -1;
  }

  findAnnotationIndexByUuid(uuid: AnnotationId): number {
    return this._annotations.findIndex((annotation) => annotation.uuid === uuid);
  }

  /**
   * Finds the index of an annotation by the ID of its feature.
   * @param featureId - The feature ID of the annotation to find.
   * @returns The index of the annotation, or -1 if not found.
   */
  findAnnotationIndexByFeatureId(featureId: AnnotationId): number {
    return this.featureIndexMap.get(featureId) ?? -1;
  }

  /**
   * Updates an annotation by its index.
   * @param index - The index of the annotation to update.
   * @param updates - The updates to apply to the annotation.
   */
  updateAnnotationByIndex(index: number, updates: Partial<Annotation>) {
    const annotation = this._annotations[index];
    const updatedAnnotation = { ...annotation, ...updates };
    this._annotations[index] = updatedAnnotation;

    this.annotationIndexMap.set(updatedAnnotation.id, index);
    this.featureIndexMap.set(updatedAnnotation.feature?.get('id'), index);
  }

  /**
   * Deletes an annotation by its UUID.
   * @param uuid - The UUID of the annotation to delete.
   */
  deleteAnnotationByUuid(uuid: AnnotationId) {
    let index = -1;

    if (uuid !== undefined) {
      index = this._annotations.findIndex((annotation) => annotation.uuid === uuid);
    }

    if (index !== -1) {
      this.deleteAnnotationByIndex(index);
    }
  }

  deleteAnnotationById(id: AnnotationId) {
    const index = this.findAnnotationIndexById(id);

    if (index !== -1) {
      this.deleteAnnotationByIndex(index);
    }
  }

  /**
   * Deletes an annotation by its index.
   * @param index - The index of the annotation to delete.
   */
  deleteAnnotationByIndex(index: number) {
    const annotation = this._annotations.splice(index, 1)[0];

    this.annotationIndexMap.delete(annotation.id);
    this.featureIndexMap.delete(annotation.feature?.get('id'));

    // Update the index map for remaining annotations
    this.buildIndexMaps(index);
  }

  /**
   * Deletes all annotations
   */
  bulkDeleteAllAnnotations(): void {
    this._annotations = [];
    this.buildIndexMaps();
  }

  /**
   * Gets all selected annotations.
   * @returns An array of selected annotations.
   */
  getSelectedAnnotations(): Annotation[] {
    return this._annotations.filter((a) => a.selected);
  }

  /**
   * Gets all visible annotations.
   * @returns An array of visible annotations.
   */
  getVisibleAnnotations(): Annotation[] {
    return this._annotations.filter((a) => a.visible);
  }

  /**
   * Gets all hidden annotations.
   * @returns An array of visible annotations.
   */
  getHiddenAnnotations(): Annotation[] {
    return this._annotations.filter((a) => !a.visible);
  }

  /**
   * Gets all rendered annotations.
   * @returns An array of rendered annotations.
   */
  getRenderedAnnotations(): Annotation[] {
    return this._annotations.filter((a) => a.drew);
  }

  /**
   * Bulk updates all annotations with the given payload.
   * @param payload - The updates to apply to all annotations.
   */
  bulkUpdateAllAnnotations(payload: Partial<Annotation>) {
    this._annotations = this._annotations.map((a, index) => {
      const updatedAnnotation = { ...a, ...payload };

      if (a.id && a.id !== updatedAnnotation.id) {
        this.annotationIndexMap.set(a.id, index);
      }

      if (payload.feature) {
        this.featureIndexMap.set(payload.feature?.get('id'), index);
      }

      return updatedAnnotation;
    });
  }

  /**
   * Sets the visibility of all annotations.
   * @param visible - Boolean indicating whether all annotations should be visible.
   */
  setVisibility(visible: boolean) {
    this._annotations.forEach((a) => {
      a.visible = visible;
    });
  }
}
