import { Theme, SxProps } from '@mui/material/styles';

import View403 from 'src/sections/error/403-view';

import { UserRoleType } from 'src/types/types-user';

import { useAuthContext } from '../hooks';

// ----------------------------------------------------------------------

type RoleBasedGuardProp = {
  roles: UserRoleType[];
  children: React.ReactNode;
  sx?: SxProps<Theme>;
};

export default function RoleBasedGuard({ roles, children, sx }: RoleBasedGuardProp) {
  const { user, authenticated } = useAuthContext();

  if (authenticated && user && roles.includes(user.role)) {
    return children;
  }

  return <View403 />;
}
