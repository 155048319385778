/* eslint-disable react-hooks/rules-of-hooks */
import { useMemo } from 'react';

import { useGetPage } from 'src/services/api/hooks/use-get-page';

import View403 from 'src/sections/error/403-view';

import { PageContext } from './page-context';
import { usePageConfig } from './use-page-config';

// ----------------------------------------------------------------------

type Props = {
  children: React.ReactNode;
};

export function PageProvider({ children }: Props) {
  const { pageConfig, blockedPage } = usePageConfig();

  if (blockedPage) return <View403 enableBackLink={false} />;

  if (pageConfig) {
    const { page, pageError } = useGetPage(pageConfig.actions.load);

    const renderError = <>Error</>; // TODO: render pageError case

    const memoizedValue = useMemo(() => page, [page]);

    if (pageError) return renderError;

    if (memoizedValue)
      return <PageContext.Provider value={memoizedValue}>{children}</PageContext.Provider>;

    return <>{children}</>;
  }

  // TODO: handle else case
}
