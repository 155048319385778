import { useBlocker } from 'react-router-dom';
import { useRef, useState, useEffect, useCallback } from 'react';

import { useTranslate } from 'src/locales';

// ----------------------------------------------------------------------

export default function useUnsavedChangesWarning(
  defaultEnabled = true,
  message = 'null',
  handleTabClosing = async () => {}
) {
  const { t } = useTranslate();

  if (message === 'null') {
    message = t('canvas.unsaved_changes_warning');
  }

  const [msg, setMsg] = useState<string>(message);
  const [enabled, setEnabled] = useState<boolean>(defaultEnabled);

  const onLocationChange = useCallback(() => {
    if (enabled) {
      return !window.confirm(message);
    }
    return false;
  }, [enabled, message]);

  usePrompt(onLocationChange, enabled);

  useEffect(() => {
    function beforeunloadListener(e: BeforeUnloadEvent) {
      if (enabled) {
        e.preventDefault();
        e.returnValue = msg; // Included for legacy support, e.g. Chrome/Edge < 119
      }
      return msg;
    }

    window.addEventListener('beforeunload', beforeunloadListener); // Detecting browser closing
    window.addEventListener('unload', handleTabClosing); // If the page does unload, the handleTabClosing function runs.

    return () => {
      window.removeEventListener('beforeunload', beforeunloadListener);
      window.removeEventListener('unload', handleTabClosing);
    };
  }, [msg, enabled, handleTabClosing]);

  return {
    enable(): void {
      setEnabled(true);
    },
    disable(): void {
      setEnabled(false);
    },
    setMessage(newMessage: string): void {
      setMsg(newMessage);
    },
    getMessage(): string {
      return msg;
    },
    setEnabled(status: boolean): void {
      setEnabled(status);
    },
    getEnabled(): boolean {
      return enabled;
    },
  };
}

// ----------------------------------------------------------------------

function usePrompt(onLocationChange: () => boolean, isEnabled: boolean) {
  const blocker = useBlocker(isEnabled ? onLocationChange : false);
  const prevState = useRef(blocker.state);

  useEffect(() => {
    if (blocker.state === 'blocked') {
      blocker.reset();
    }
    prevState.current = blocker.state;
  }, [blocker]);
}
