import 'reflect-metadata';
import { SWRConfig } from 'swr';
import { Suspense } from 'react';
import ReactDOM from 'react-dom/client';
import { RouterProvider } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import { Provider as ProviderStore } from 'react-redux';
import { Provider as ProviderInversify } from 'inversify-react';

import router from 'src/routes/sections/index';

import { store } from 'src/store';
import { container } from 'src/services/container';
import { initAppServices } from 'src/helpers/init-app-services';
import { MittProvider } from 'src/services/canvas/hooks/use-mitt';

// ----------------------------------------------------------------------

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

initAppServices();

root.render(
  <HelmetProvider>
    <Suspense>
      <ProviderInversify container={container}>
        <ProviderStore store={store}>
          <MittProvider>
            <SWRConfig>
              <RouterProvider router={router} />
            </SWRConfig>
          </MittProvider>
        </ProviderStore>
      </ProviderInversify>
    </Suspense>
  </HelmetProvider>
);
