import mitt, { Emitter } from 'mitt';

import type AnnotationClass from 'src/services/canvas/annotation-class';
import type { HistoryItem } from 'src/services/canvas/types/types-canvas';

// ----------------------------------------------------------------------

export type CanvasEvents = {
  'classes-updated': { annotationClasses: AnnotationClass[] };
  'active-class': { activeClass: AnnotationClass | undefined };
  'undo-item': { item: HistoryItem | undefined };
  'redo-item': { item: HistoryItem | undefined };

  'BrushManager-reset': undefined;
};

export interface CanvasMittContextType {
  emitter: Emitter<CanvasEvents>;
}

const canvasEmitter: Emitter<CanvasEvents> = mitt();

export default canvasEmitter;
