import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { DynamicActionItem } from 'src/services/api/types/types-dynamic-components';

// ----------------------------------------------------------------------

type StateType = {
  appLoading: boolean;
  writeApiCallsPending: number;
  meAction: DynamicActionItem | null;
};

const initialState: StateType = {
  meAction: null,
  appLoading: false,
  writeApiCallsPending: 0,
};

// ----------------------------------------------------------------------

export const contentSlice = createSlice({
  name: 'content',
  initialState,
  reducers: {
    turnOnAppLoader: (state) => {
      state.appLoading = true;
    },
    turnOffAppLoader: (state) => {
      state.appLoading = false;
    },
    toggleAppLoader: (state) => {
      state.appLoading = !state.appLoading;
    },
    setAppLoader: (state, action: PayloadAction<StateType['appLoading']>) => {
      state.appLoading = action.payload;
    },
    setMeAction: (state, action: PayloadAction<StateType['meAction']>) => {
      state.meAction = action.payload;
    },
    startRequest: (state, action: PayloadAction<{ isWriteOp: boolean }>) => {
      if (action.payload.isWriteOp) state.writeApiCallsPending += 1;
    },
    endRequest: (state, action: PayloadAction<{ isWriteOp: boolean }>) => {
      if (action.payload.isWriteOp) state.writeApiCallsPending -= 1;
    },
  },
});

export const {
  turnOnAppLoader,
  turnOffAppLoader,
  toggleAppLoader,
  setAppLoader,
  setMeAction,
  startRequest,
  endRequest,
} = contentSlice.actions;

export default contentSlice.reducer;
