export enum AmplitudeEvents {
  AuthRegisterAccount = 'Register New Account',
  SnapshotCreateAiModel = 'Request New AI Model',

  // ----------------------------------------------------------------------
  // Canvas
  // ----------------------------------------------------------------------
  CanvasToolSelected = 'Canvas: Tool Selected',
  CanvasShortcutPressed = 'Canvas: Shortcut Pressed',
  CanvasBrushSizeChanged = 'Canvas: Brush Size Changed',
  CanvasOverviewMapUsed = 'Canvas: Overview Map Used',
  CanvasImageNavigated = 'Canvas: Image Navigated',
  CanvasUndoRedoAction = 'Canvas: Undo/Redo Action',
  CanvasMovementStickUsed = 'Canvas: Movement Stick Used',
  CanvasAnnotationDrawn = 'Canvas: Annotation Drawn',
  CanvasAnnotationModified = 'Canvas: Annotation Modified',
  CanvasAnnotationAction = 'Canvas: Annotation Action',
  CanvasAnnotationReassigned = 'Canvas: Annotation Reassigned',
  CanvasAnnotationInteraction = 'Canvas: Annotation Interaction',
  CanvasCopyPasteAction = 'Canvas: Copy/Paste Action',
  CanvasTissueAction = 'Canvas: Tissue Action',
  CanvasTissueInteraction = 'Canvas: Tissue Interaction',
}
