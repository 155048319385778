import { createSlice } from '@reduxjs/toolkit';

import { PostCanvasSaveRes } from 'src/services/api/types/types-req-res';
import { featCanvasOverviewMap, featCanvasMovementControl } from 'src/configs/feature-flags';
import {
  configCanvasPageImageCarousel,
  configCanvasPageAnnotationsManager,
} from 'src/configs/config-canvas';

import { HandleSaveChangesProps } from 'src/sections/annotations-manager/types';

// ----------------------------------------------------------------------

type StateType = {
  // TODO: move it to canvas layout context
  canvasLayout: {
    sandbox: { isVisible: boolean };
    statsCard: { isVisible: boolean };
    zoomControl: { isVisible: boolean };
    threadsManager: { isVisible: boolean };
    movementControl: { isVisible: boolean };
    imagesCarousel: { isExpanded: boolean };
    annotationsManager: { isVisible: boolean; isExpanded: boolean };
    overviewMap: { isVisible: boolean; isExpanded: boolean };
  };
  isCanvasSaving: boolean;
  isCanvasPredicting: boolean;
  saveChangesFunction: (payload: HandleSaveChangesProps) => Promise<PostCanvasSaveRes>;
};

const initialState: StateType = {
  canvasLayout: {
    sandbox: { isVisible: false },
    statsCard: { isVisible: false },
    zoomControl: { isVisible: false },
    threadsManager: { isVisible: false },
    movementControl: { isVisible: featCanvasMovementControl.isEnabled },
    imagesCarousel: { isExpanded: configCanvasPageImageCarousel.initialState.isExpanded },
    annotationsManager: {
      isVisible: false,
      isExpanded: configCanvasPageAnnotationsManager.initialState.isExpanded,
    },
    overviewMap: { isVisible: featCanvasOverviewMap.isEnabled, isExpanded: true },
  },
  isCanvasSaving: false,
  isCanvasPredicting: false,
  saveChangesFunction: async () => ({
    create: { ai_project_snapshot_classes: [], annotations: [] },
    update: { ai_project_snapshot_classes: [], annotations: [] },
  }),
};

// ----------------------------------------------------------------------

export const slicePageCanvas = createSlice({
  name: 'pageCanvas',
  initialState,
  reducers: {
    setCanvasLayoutVisibility: (
      state,
      {
        payload,
      }: {
        payload: {
          section:
            | 'sandbox'
            | 'statsCard'
            | 'zoomControl'
            | 'threadsManager'
            | 'movementControl'
            | 'annotationsManager'
            | 'overviewMap';
          isVisible: boolean;
        };
      }
    ) => {
      state.canvasLayout[payload.section].isVisible = payload.isVisible;
    },
    setCanvasLayoutExpandability: (
      state,
      {
        payload,
      }: {
        payload: {
          section: 'annotationsManager' | 'imagesCarousel' | 'overviewMap';
          isExpanded: boolean;
        };
      }
    ) => {
      state.canvasLayout[payload.section].isExpanded = payload.isExpanded;
    },
    setCanvasSaving: (state, { payload }) => {
      state.isCanvasSaving = payload;
    },
    setCanvasPredicting: (state, { payload }) => {
      state.isCanvasPredicting = payload;
    },
    setSaveChangesFunction: (state, { payload }) => {
      state.saveChangesFunction = payload;
    },
    resetPageCanvas: (state) => {
      // TODO: move it to canvas layout context -- then there is no need to reset them
      state.canvasLayout.sandbox.isVisible = initialState.canvasLayout.sandbox.isVisible;
      state.canvasLayout.statsCard.isVisible = initialState.canvasLayout.statsCard.isVisible;
      state.canvasLayout.zoomControl.isVisible = initialState.canvasLayout.zoomControl.isVisible;
      state.canvasLayout.overviewMap.isExpanded = initialState.canvasLayout.overviewMap.isExpanded;
      state.canvasLayout.overviewMap.isVisible = initialState.canvasLayout.overviewMap.isVisible;
      state.canvasLayout.threadsManager.isVisible =
        initialState.canvasLayout.threadsManager.isVisible;
      state.canvasLayout.imagesCarousel.isExpanded =
        initialState.canvasLayout.imagesCarousel.isExpanded;
      state.canvasLayout.annotationsManager.isExpanded =
        initialState.canvasLayout.annotationsManager.isExpanded;

      state.isCanvasSaving = initialState.isCanvasSaving;
      state.isCanvasPredicting = initialState.isCanvasPredicting;
      state.saveChangesFunction = initialState.saveChangesFunction;
    },
  },
  selectors: {
    isCanvasLayoutExpanded: (
      state,
      section: 'annotationsManager' | 'imagesCarousel' | 'overviewMap'
    ) => state.canvasLayout[section].isExpanded,
    isCanvasLayoutVisible: (
      state,
      section:
        | 'sandbox'
        | 'statsCard'
        | 'zoomControl'
        | 'threadsManager'
        | 'movementControl'
        | 'overviewMap'
    ) => state.canvasLayout[section].isVisible,
  },
});

export const {
  setCanvasLayoutVisibility,
  setCanvasLayoutExpandability,
  setCanvasSaving,
  setSaveChangesFunction,
  setCanvasPredicting,
  resetPageCanvas,
} = slicePageCanvas.actions;

export const selectorsPageCanvas = slicePageCanvas.selectors;

export default slicePageCanvas.reducer;
