import Box, { BoxProps } from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import LinearProgress from '@mui/material/LinearProgress';
import CircularProgress from '@mui/material/CircularProgress';

// ----------------------------------------------------------------------

interface Props extends BoxProps {
  text?: string;
  circular?: boolean;
}

export default function LoadingScreen({ text, sx, circular = false, ...other }: Props) {
  return (
    <Box
      sx={{
        px: 5,
        width: 1,
        flexGrow: 1,
        minHeight: 1,
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        justifyContent: 'center',
        ...sx,
      }}
      {...other}
    >
      {circular ? (
        <CircularProgress color='inherit' />
      ) : (
        <LinearProgress color='inherit' sx={{ width: 1, maxWidth: 360 }} />
      )}

      {text && <Typography>{text}</Typography>}
    </Box>
  );
}
