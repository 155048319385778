import { useMemo } from 'react';

import { useParams, useActivePath } from 'src/routes/hooks';

import { useAuthContext } from 'src/services/auth/hooks';
import { PageType } from 'src/helpers/mapper-page/enum-pages';
import { DynamicComponent } from 'src/services/api/types/types-dynamic-components';

import { parseActions } from './helpers';

// ----------------------------------------------------------------------

type ReturnType = {
  pageConfig: DynamicComponent<PageType> | null;
  blockedPage: boolean;
};

export function usePageConfig(): ReturnType {
  const path = useActivePath();
  const dynamicParams = useParams();
  const { allowedPages } = useAuthContext();

  const { pageConfig, blockedPage } = useMemo(() => {
    const config = allowedPages.find((page) => page.id === path.id);

    if (!config) return { pageConfig: null, blockedPage: true };

    const pgConfig = parseActions(config, dynamicParams);

    return { pageConfig: pgConfig, blockedPage: false };
  }, [allowedPages, path, dynamicParams]);

  return { pageConfig, blockedPage };
}
