import { injectable } from 'inversify';
import { captureException } from '@sentry/react';

import { ILoggerService } from './logger.interface';

// ----------------------------------------------------------------------

@injectable()
export class LoggerService implements ILoggerService {
  // eslint-disable-next-line @typescript-eslint/no-useless-constructor
  constructor() {
    //
  }

  // eslint-disable-next-line class-methods-use-this
  error(error: any, message: string) {
    console.error(error, message);
    captureException(error);
  }
}
