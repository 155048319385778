import { DeviceType, detectDeviceType } from 'src/utils/user-agent';

import { APP_ENV } from '../config-global';
import { NodeEnvType } from '../types/enum-envs';

// ----------------------------------------------------------------------

export class ConfigBase<T> {
  readonly name: string;

  readonly environment: NodeEnvType;

  readonly device: DeviceType;

  private _configs: Record<NodeEnvType, T | { [k in DeviceType]: T }>;

  constructor(
    name: string,
    local: T | { [k in DeviceType]: T }, // Default value for configs (local config)
    overrides?: Partial<Record<Exclude<NodeEnvType, NodeEnvType.Local>, T>> // Overrides for other environments except local
  ) {
    this.name = name;
    this.environment = APP_ENV;
    this.device = detectDeviceType();
    this._configs = {
      [NodeEnvType.Local]: local,
      [NodeEnvType.Development]: overrides?.[NodeEnvType.Development] ?? local,
      [NodeEnvType.Staging]: overrides?.[NodeEnvType.Staging] ?? local,
      [NodeEnvType.Production]: overrides?.[NodeEnvType.Production] ?? local,
    };

    this.validateConfigs();
  }

  // Validate that required configurations are set for each environment
  private validateConfigs(): void {
    Object.keys(this._configs).forEach((env) => {
      const config = this._configs[env as NodeEnvType];
      if (!config) {
        throw new Error(`Constructing ${this.name}: Missing configuration for ${env}`);
      }
    });
  }

  // Helper type to determine if a value is a device-specific config
  // eslint-disable-next-line class-methods-use-this
  protected isDeviceConfig<D = T>(
    config: D | { [k in DeviceType]: D }
  ): config is { [k in DeviceType]: D } {
    return (
      config &&
      typeof config === 'object' &&
      Object.values(DeviceType).every((device) => device in config)
    );
  }

  // Retrieve the config based on the environment with fallback
  getSetup(env: NodeEnvType = this.environment): T {
    let config = this._configs[env];

    if (!config) {
      console.warn(`Config for ${env} not found. Falling back to 'local' config.`);
      config = this._configs[NodeEnvType.Local];
    }

    if (this.isDeviceConfig(config)) {
      config = config[this.device];
    }

    return config;
  }
}
