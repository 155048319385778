import * as amplitude from '@amplitude/analytics-browser';

import { LocalStorage } from 'src/helpers/local-storage';
import { AMPLITUDE_API_KEY } from 'src/configs/config-services';
import { StorageKeysType } from 'src/configs/types/enum-storage-keys';

import { IUser } from 'src/types/types-user';

import { AmplitudeEvents } from './enum-events';
import { AmplitudeEventPayloads } from './event-map';

// ----------------------------------------------------------------------

export default class AmplitudeService {
  private static instance: AmplitudeService;

  private static identifyEvent = new amplitude.Identify();

  private static groupIdentifyEvent = new amplitude.Identify();

  private constructor() {
    const user = LocalStorage.getItem(StorageKeysType.User);
    amplitude.init(AMPLITUDE_API_KEY, user?.email);
  }

  // ----------------------------------------------------------------------

  public static getInstance(): AmplitudeService {
    return this.instance;
  }

  public static init(): AmplitudeService {
    if (!this.instance) {
      this.instance = new AmplitudeService();
    }

    return this.getInstance();
  }

  public static track<T extends AmplitudeEvents>(
    eventName: T,
    eventProperties?: AmplitudeEventPayloads[T],
    eventOptions?: amplitude.Types.EventOptions
  ): void {
    amplitude.track(eventName, { ...eventProperties }, eventOptions);
  }

  public static setUser(user: IUser | undefined): void {
    if (!user) return;

    amplitude.setUserId(user.email);
    this.identifyEvent.set('id', user.id);
    this.identifyEvent.set('role', user.role);
    this.identifyEvent.set('email', user.email);
    this.identifyEvent.set('org_id', user.org_id);
    this.identifyEvent.set('last_name', user.last_name);
    this.identifyEvent.set('first_name', user.first_name);

    amplitude.setGroup('organization', user.org_id);
    this.groupIdentifyEvent.set('org_id', user.org_id);
  }

  public static reset(): void {
    amplitude.reset();
  }
}
