import { AxiosError } from 'axios';
import * as Sentry from '@sentry/react';

import { store } from 'src/store';
import { APP_ENV } from 'src/configs/config-global';
import { SENTRY_DSN, configSentry } from 'src/configs/config-services';

import { IUser } from 'src/types/types-user';

import { SentryTags, SentryErrorType } from './types';

// ----------------------------------------------------------------------

export function initSentryService() {
  Sentry.init({
    dsn: SENTRY_DSN,
    environment: APP_ENV,
    debug: configSentry.debug,
    enabled: configSentry.isEnabled,
    integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],

    // Tracing
    tracesSampleRate: 1.0,

    // Session Replay
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,

    ignoreErrors: [
      // You can configure a list of errors you don't want to track
    ],
    beforeSend(event, hint) {
      const error = hint.originalException;
      const state = store.getState();

      // Add additional context
      event.extra = {
        ...event.extra,
        // Add other relevant state slices
      };

      // Filter out specific errors
      if (error instanceof AxiosError) {
        if (error.status === 404) return null; // Don't track 404s

        event.level = error.response?.status === 500 ? 'error' : 'warning';
        event.extra = {
          ...event.extra,
          request: {
            url: error.config?.url,
            method: error.config?.method,
            headers: error.config?.headers.toJSON(),
            data: error.config?.data,
          },
          response: {
            status: error.response?.status,
            data: error.response?.data,
          },
          state: {
            pendingApiCalls: state.app.writeApiCallsPending,
          },
        };
        event.tags = {
          ...event.tags,
          [SentryTags.ERROR_TYPE]: SentryErrorType.API_ERROR,
          [SentryTags.ERROR_STATUS]: error.response?.status,
          [SentryTags.ERROR_CODE]: error.code,
        };
      }

      return event;
    },
  });
}

export const setSentryUser = (user: IUser) => {
  Sentry.setUser({
    email: user.email,
  });
};

export const resetSentryUser = () => {
  Sentry.setUser(null);
};
