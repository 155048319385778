import { lazy, Suspense } from 'react';
import { Outlet, Navigate } from 'react-router-dom';

import i18n from 'src/locales/i18n';
import { GuestGuard } from 'src/services/auth/guard';
import { WEBSITE_NAME } from 'src/configs/config-global';
import AuthClassicLayout from 'src/layouts/auth/classic';

import { SplashScreen } from 'src/components/loading-screen';

import { paths } from '../paths';

// ----------------------------------------------------------------------

const LoginPage = lazy(() => import('src/pages/auth/login'));
const RegisterPage = lazy(() => import('src/pages/auth/register'));

// ----------------------------------------------------------------------

export const authRoutes = [
  {
    path: 'auth',
    element: (
      <GuestGuard>
        <Suspense fallback={<SplashScreen />}>
          <Outlet />
        </Suspense>
      </GuestGuard>
    ),
    children: [
      { index: true, element: <Navigate to={paths.auth.login.to()} replace /> },
      {
        path: 'register',
        element: (
          <AuthClassicLayout
            title={i18n.t('auth.get_started_with', { website: WEBSITE_NAME })}
            image='/assets/illustrations/illustration_authentication.svg'
          >
            <RegisterPage />
          </AuthClassicLayout>
        ),
      },
      {
        path: 'login',
        element: (
          <AuthClassicLayout
            title={i18n.t('auth.welcome_to', { website: WEBSITE_NAME })}
            image='/assets/illustrations/illustration_authentication.svg'
          >
            <LoginPage />
          </AuthClassicLayout>
        ),
      },
    ],
  },
];
