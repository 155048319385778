import Map from 'ol/Map';
import Feature from 'ol/Feature';
import VectorLayer from 'ol/layer/Vector';
import { Polygon, Geometry } from 'ol/geom';
import VectorSource from 'ol/source/Vector';

import { featCanvasCopyPaste } from 'src/configs/feature-flags';
import ModeManager from 'src/services/canvas-tools/mode-manager';
import { Annotation } from 'src/services/canvas/types/types-canvas';
import { AmplitudeEvents, AmplitudeService } from 'src/services/amplitude';

import AnnotationClassBaseManager from './annotation-class-base-manager';
import AnnotationClassAnnotationsManager from './annotation-class-annotations-manager';

// ----------------------------------------------------------------------

export default class AnnotationClassCopyManager extends AnnotationClassBaseManager {
  private source: VectorSource<Feature<Geometry>>;

  private copiedFeatures: Feature[] = [];

  // ----------------------------------------------------------------------

  constructor(
    private map: Map,
    private layer: VectorLayer<Feature<Geometry>>,
    private annotationsManager: AnnotationClassAnnotationsManager
  ) {
    super();
    this.source = layer.getSource() as VectorSource;

    this.addListeners();
  }

  // ----------------------------------------------------------------------

  addListeners() {
    if (featCanvasCopyPaste.isEnabled) {
      document.addEventListener('keydown', this.handleKeydown.bind(this));
    }
  }

  removeListeners() {
    if (featCanvasCopyPaste.isEnabled) {
      document.removeEventListener('keydown', this.handleKeydown.bind(this));
    }
  }

  copy(annotations: Annotation[] | null = null) {
    const arr =
      annotations ??
      this.annotationsManager.annotations.filter((annotation) => annotation.selected);

    this.copiedFeatures = arr.map((annotation) => annotation.feature.clone());

    AmplitudeService.track(AmplitudeEvents.CanvasCopyPasteAction, {
      action: 'copy',
      'items-count': this.copiedFeatures.length,
      'zoom-level': this.map.getView().getZoom() || 0,
      'selected-tool': ModeManager.getInstance().getMode(),
    });
  }

  getCount() {
    return this.copiedFeatures.length;
  }

  paste(position: { x: number; y: number } | null = null) {
    if (!this.copiedFeatures.length) return;

    let positionPixel = position;
    if (!positionPixel) {
      positionPixel = this.getMapCenter();
    }

    const { x, y } = positionPixel;

    this.copiedFeatures.forEach((feature) => {
      const cloned = feature.clone();
      const geo = this.translateFeatureByPixels(feature, x, y);
      geo.clone();
      cloned.setGeometry(geo);
      cloned.setProperties({
        id: null,
        dbid: null,
        area: null,
        uuid: null,
        locked: false,
        hidden: false,
        class_uuid: null, // TODO: manage properties (setter and getter with types)
        selected: false,
      });
      this.source.addFeature(cloned);
    });

    AmplitudeService.track(AmplitudeEvents.CanvasCopyPasteAction, {
      action: 'paste',
      'items-count': this.copiedFeatures.length,
      'zoom-level': this.map.getView().getZoom() || 0,
      'selected-tool': ModeManager.getInstance().getMode(),
    });
  }

  translateFeatureByPixels(feature: Feature, pixelX: number, pixelY: number) {
    const mapCoord = this.map.getCoordinateFromPixel([pixelX, pixelY]);

    const geometry = (feature as unknown as Feature<Polygon>)
      .clone()
      .getGeometry()
      ?.clone() as Polygon;

    const currentCoord = geometry.getInteriorPoint().getCoordinates();

    const dx = mapCoord[0] - currentCoord[0];
    const dy = mapCoord[1] - currentCoord[1];

    geometry.translate(dx, dy);

    return geometry;
  }

  private getMapCenter() {
    const center = this.map.getView().getCenter();

    if (!center) return { x: 20, y: 20 };

    const centerPixel = this.map.getPixelFromCoordinate(center);

    return { x: centerPixel[0], y: centerPixel[1] };
  }

  private handleKeydown(event: KeyboardEvent) {
    const ctrl = event.ctrlKey || event.metaKey;
    const vPressed = event.key === 'v';
    const cPressed = event.key === 'c';

    const isPaste = ctrl && vPressed;
    const isCopy = ctrl && cPressed;

    if (!isPaste && !isCopy) return;

    if (isPaste) {
      this.paste();
    } else {
      this.copy();
    }
  }
}
