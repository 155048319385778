import { Params } from './enum-params';

// ----------------------------------------------------------------------

export enum RoutePlaceholder {
  ImageId = `<int:${Params.ImageId}>`,
  FolderId = `<int:${Params.FolderId}>`,
  AiModelId = `<int:${Params.AiModelId}>`,
  ProjectId = `<int:${Params.ProjectId}>`,
  SnapshotId = `<int:${Params.SnapshotId}>`,
  SketchId = `<int:${Params.SketchId}>`,
  ClassId = `<int:${Params.ClassId}>`,
  ThreadId = `<int:${Params.ThreadId}>`,
  CommentId = `<int:${Params.CommentId}>`,
  AnnotationId = `<int:${Params.AnnotationId}>`,
  JobId = `<int:${Params.JobId}>`,
}
