import { useState, useEffect, useCallback } from 'react';

import { StorageKey, LocalStorage, StoragePayloads } from 'src/helpers/local-storage';

// ----------------------------------------------------------------------

export function useLocalStorage<T extends StorageKey>(key: T, initialState: StoragePayloads[T]) {
  const [state, setState] = useState<StoragePayloads[T]>(initialState);

  useEffect(() => {
    const restored = LocalStorage.getItem(key);

    if (restored) {
      setState((prevValue) => ({
        ...(prevValue as any),
        ...(restored as any),
      }));
    }
  }, [key]);

  const updateState = useCallback(
    (updateValue: Partial<StoragePayloads[T]>) => {
      setState((prevValue) => {
        LocalStorage.setItem(key, {
          ...(prevValue as any),
          ...updateValue,
        });

        return {
          ...(prevValue as any),
          ...updateValue,
        };
      });
    },
    [key]
  );

  const update = useCallback(
    <K extends keyof StoragePayloads[T]>(name: K, updateValue: StoragePayloads[T][K]) => {
      updateState({ [name]: updateValue } as unknown as Partial<StoragePayloads[T]>);
    },
    [updateState]
  );

  const reset = useCallback(() => {
    LocalStorage.removeItem(key);
    setState(initialState);
  }, [initialState, key]);

  return {
    state,
    update,
    reset,
  };
}
