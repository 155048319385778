import Map from 'ol/Map';
import { Pixel } from 'ol/pixel';
import Feature from 'ol/Feature';
import Point from 'ol/geom/Point';
import { Extent } from 'ol/extent';
import VectorLayer from 'ol/layer/Vector';
import { Coordinate } from 'ol/coordinate';
import VectorSource from 'ol/source/Vector';
import MapBrowserEvent from 'ol/MapBrowserEvent';

import { EventHub, EventTypes } from 'src/services/event-hub';
import { createCanvasLayer } from 'src/services/canvas/helpers/helpers';
import { configCanvasThreads, configCanvasAdaptiveZoom } from 'src/configs/config-canvas';

import { IThread } from 'src/types/types-thread';

import { Thread } from './types';

// ----------------------------------------------------------------------

export default class ThreadsManager {
  private source!: VectorSource;

  private layer!: VectorLayer<Feature>;

  private threads: Thread[] = [];

  private temporaryThreadFeature: Feature<Point> | undefined;

  constructor(
    private map: Map,
    extent: Extent
  ) {
    this.initializeLayer(extent);
    this.bindMapEvents();
    this.bindSourceEvents();
  }

  private initializeLayer(extent: Extent): void {
    const { layer, source } = createCanvasLayer(configCanvasThreads.style.layer, Infinity);

    layer.setExtent(extent);

    this.map.addLayer(layer);
    this.source = source;
    this.layer = layer;
    this.layer.setVisible(false);
  }

  private bindSourceEvents(): void {
    this.source.on(['addfeature', 'removefeature'], this.handleSourceChange.bind(this));
  }

  private handleSourceChange(): void {
    EventHub.emit(EventTypes.THREADS_UPDATE_FEATURES, { threads: this.threads });
  }

  private bindMapEvents(): void {
    this.map.on('click', this.handleClick.bind(this));
    this.map.on('pointermove', this.handlePointerMove.bind(this));
    this.map.on('pointerdrag', this.handlePointerDrag.bind(this));
  }

  public init(threads: IThread[]): void {
    this.reset();
    this.layer.setVisible(true);
    this.createThreadFeatures(threads);
    this.changeCursorStyle('add');
    EventHub.emit(EventTypes.THREADS_INITIALIZED, undefined);
  }

  private createThreadFeatures(threads: IThread[]): void {
    const features: Thread['feature'][] = [];
    this.threads = threads.map((t) => {
      const feature = this.createThreadFeature(t);
      features.push(feature);
      return { ...t, feature };
    });
    this.source.addFeatures(features);
  }

  // eslint-disable-next-line class-methods-use-this
  private createThreadFeature(thread: IThread): Feature<Point> {
    const geo = new Point(thread.location.coordinate);
    const feature = new Feature<Point>();
    feature.set('thread_id', thread.id);
    feature.setGeometry(geo);
    feature.setStyle(configCanvasThreads.style.feature);
    return feature;
  }

  public reset(): void {
    this.threads = [];
    this.source.clear();
    this.layer.setVisible(false);
    this.clearThreadInteractions();
    this.changeCursorStyle('normal');
    this.unbindMapEvents();
    EventHub.emit(EventTypes.THREADS_RESET, undefined);
  }

  private unbindMapEvents(): void {
    this.map.un('click', this.handleClick.bind(this));
    this.map.un('pointermove', this.handlePointerMove.bind(this));
    this.map.un('pointerdrag', this.handlePointerDrag.bind(this));
  }

  public fitViewToThread(
    id: string,
    options: { triggerEvtClickThread?: boolean } = { triggerEvtClickThread: true }
  ): void {
    this.clearThreadInteractions();
    const thread = this.findThreadById(id);
    if (!thread) return;

    const geometry = thread.feature.getGeometry()!;
    this.fitViewToGeometry(geometry, () => {
      if (options?.triggerEvtClickThread) {
        this.emitThreadClickEvent(geometry, thread);
      }
    });
  }

  private findThreadById(id: string): Thread | undefined {
    return this.threads.find((t) => t.id === id);
  }

  private fitViewToGeometry(geometry: Point, callback: () => void): void {
    this.map.getView().fit(geometry, {
      duration: 1000,
      minResolution: configCanvasAdaptiveZoom.minResolutionToLocateAnnotation,
      padding: [100, 100, 100, 100],
      callback,
    });
  }

  private emitThreadClickEvent(geometry: Point, thread: Thread): void {
    EventHub.emit(EventTypes.THREADS_CLICK, {
      pixel: this.map.getPixelFromCoordinate(geometry.getCoordinates()),
      thread,
    });
  }

  private getThreadFromPixel(pixel: Pixel): Thread | undefined {
    const features = this.map.getFeaturesAtPixel(pixel).filter((f) => f.get('thread_id'));
    if (!features.length) return undefined;
    const threadId = features[0].get('thread_id');
    return this.findThreadById(threadId);
  }

  private changeCursorStyle(state: 'hover' | 'normal' | 'add'): void {
    const cursorStyles = {
      add: configCanvasThreads.style.cursor,
      hover: 'pointer',
      normal: '',
    };
    this.map.getViewport().style.cursor = cursorStyles[state] || '';
  }

  private clearThreadInteractions(): void {
    this.removeTempFeature();
    EventHub.emit(EventTypes.MAP_CLICK, { pixel: [0, 0], coordinate: undefined });
    EventHub.emit(EventTypes.THREADS_CLICK, { pixel: [0, 0], thread: undefined });
    EventHub.emit(EventTypes.THREADS_HOVER, { pixel: [0, 0], thread: undefined });
  }

  private addTempFeature(coord: Coordinate): Feature<Point> {
    const point = new Point(coord);
    const feature = new Feature<Point>(point);
    feature.setStyle(configCanvasThreads.style.feature);
    this.source.addFeature(feature);
    this.temporaryThreadFeature = feature;
    this.changeCursorStyle('normal');
    return feature;
  }

  public removeTempFeature(): void {
    if (this.temporaryThreadFeature) {
      this.source.removeFeature(this.temporaryThreadFeature);
      this.temporaryThreadFeature = undefined;
      this.changeCursorStyle('add');
    }
  }

  private handleClick(event: MapBrowserEvent<any>): void {
    if (!this.layer.getVisible()) return;
    const thread = this.getThreadFromPixel(event.pixel);
    if (thread) {
      this.fitViewToThread(thread.id);
    } else {
      this.handleNonThreadClick(event);
    }
  }

  private handleNonThreadClick(event: MapBrowserEvent<any>): void {
    const { pixel } = event;
    let coordinate: Coordinate | undefined;

    if (this.temporaryThreadFeature) {
      coordinate = undefined;
      this.removeTempFeature();
    } else {
      // eslint-disable-next-line prefer-destructuring
      coordinate = event.coordinate;
      this.addTempFeature(event.coordinate);
    }

    this.emitClickEvents(pixel, coordinate);
  }

  // eslint-disable-next-line class-methods-use-this
  private emitClickEvents(pixel: Pixel, coordinate: Coordinate | undefined): void {
    EventHub.emit(EventTypes.MAP_CLICK, { pixel, coordinate });
    EventHub.emit(EventTypes.THREADS_CLICK, { pixel, thread: undefined });
  }

  private handlePointerMove(event: MapBrowserEvent<any>): void {
    if (!this.layer.getVisible()) return;
    const thread = this.getThreadFromPixel(event.pixel);
    const pixel = this.getPixelForHoverEvent(thread, event.pixel);
    this.updateCursorStyle(thread);
    EventHub.emit(EventTypes.THREADS_HOVER, { pixel, thread });
  }

  private getPixelForHoverEvent(thread: Thread | undefined, eventPixel: Pixel): Pixel {
    if (thread) {
      return this.map.getPixelFromCoordinate(thread.feature.getGeometry()!.getCoordinates());
    }
    return eventPixel;
  }

  private updateCursorStyle(thread: Thread | undefined): void {
    if (thread) {
      this.changeCursorStyle('hover');
    } else if (!this.temporaryThreadFeature) {
      this.changeCursorStyle('add');
    } else {
      this.changeCursorStyle('normal');
    }
  }

  private handlePointerDrag(event: MapBrowserEvent<any>): void {
    this.clearThreadInteractions();
  }
}
