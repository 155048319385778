/* eslint-disable class-methods-use-this */

import MapService from 'src/services/canvas/map-service';
import { EventHub, EventTypes } from 'src/services/event-hub';
import { AmplitudeEvents, AmplitudeService } from 'src/services/amplitude/';
import {
  Brush,
  DrawType,
  ToolsType,
  SelectType,
  AnnotationMode,
  ThreadCommentType,
} from 'src/services/canvas/types/types-canvas';

import { DEFAULT_MODE } from './config-canvas-tools';

// ----------------------------------------------------------------------

export default class ModeManager {
  private static instance: ModeManager | undefined;

  private mode: AnnotationMode = null;

  public defaultMode: AnnotationMode = DEFAULT_MODE;

  private previousMode: AnnotationMode = null;

  private modeSelectedAt: number = Date.now();

  private constructor() {
    EventHub.on(EventTypes.TOOLBAR_SELECT_TOOL, ({ tool, shortcut }) =>
      this.setMode(tool, shortcut)
    );
    EventHub.on(EventTypes.CANVAS_CLEANUP, () => this.destroy());
    this.setMode(DEFAULT_MODE);
  }

  static getInstance() {
    if (!this.instance) {
      this.instance = new ModeManager();
    }

    return this.instance;
  }

  getMode() {
    return this.mode;
  }

  setMode(mode: AnnotationMode, shortcut?: string, force = false): boolean {
    if (!this.isValid(mode)) return false;

    const redirect = this.hasRedirect(mode);
    if (redirect) {
      return this.setMode(redirect);
    }

    if (this.mode === mode && !force) return false;

    this.previousMode = this.mode;
    this.beforeModeChange(mode);

    this.handleModeChange(mode);

    this.afterModeChange(mode, shortcut);
    this.mode = mode;
    this.modeSelectedAt = Date.now();

    EventHub.emit(EventTypes.TOOLBAR_TOOL_SELECTED, { tool: mode });

    return true;
  }

  reset() {
    this.setMode(DEFAULT_MODE, undefined, true);
  }

  destroy() {
    this.reset();
    EventHub.off(EventTypes.TOOLBAR_TOOL_SELECTED);
    EventHub.off(EventTypes.TOOLBAR_SELECT_TOOL);
    EventHub.off(EventTypes.CANVAS_CLEANUP);
    this.mode = null;
    this.previousMode = null;
    this.modeSelectedAt = 0;
    ModeManager.instance = undefined;
  }

  private handleModeChange(mode: AnnotationMode) {
    const map = MapService.getInstance();
    const activeAnnotationClass = map.getActiveAnnotationClass();
    const { annotationClasses } = map;

    switch (mode) {
      case SelectType.Single:
        annotationClasses.forEach((annotClass) => annotClass.interactor.enableSelecting(mode));
        break;
      case SelectType.Box:
      case SelectType.Polygon:
        activeAnnotationClass?.interactor.enableSelecting(mode);
        break;
      case SelectType.Translate:
        annotationClasses.forEach((annotClass) => annotClass.interactor.enableTranslate());
        break;
      case DrawType.Point:
      case DrawType.Circle:
      case DrawType.Polygon:
      case DrawType.Rectangle:
        activeAnnotationClass?.interactor.setDrawType(mode);
        break;
      case ThreadCommentType.Comment:
        // nothing
        break;
      case Brush.Brush:
      case Brush.BrushDraw:
        // nothing
        break;
      default:
        break;
    }
  }

  private beforeModeChange(mode: AnnotationMode) {
    const mapService = MapService.getInstance();

    if ([Brush.Brush, Brush.BrushDraw].includes(this.mode as Brush)) {
      mapService.brushManager?.resetBrushData();
    }

    mapService.unselectAllAnnotations();
    mapService.disableAllInteractions();
    mapService.threadsManager?.reset();
  }

  private afterModeChange(mode: AnnotationMode, shortcut: string | undefined) {
    try {
      const duration = this.previousMode ? Date.now() - this.modeSelectedAt : 0;

      AmplitudeService.track(AmplitudeEvents.CanvasToolSelected, {
        'selected-tool': mode,
        'previous-duration': duration,
        'previous-tool': this.previousMode,
        method: shortcut ? 'shortcut' : 'toolbar',
      });
    } catch (e) {
      //
    }
  }

  private hasRedirect(nextMode: AnnotationMode) {
    if (this.mode === ThreadCommentType.Comment && this.mode === nextMode) {
      return SelectType.Single;
    }

    // add other rules

    return undefined;
  }

  private isValid(mode: any) {
    return (
      Object.values(DrawType).includes(mode) ||
      Object.values(Brush).includes(mode) ||
      Object.values(SelectType).includes(mode) ||
      Object.values(ThreadCommentType).includes(mode) ||
      Object.values(ToolsType).includes(mode)
    );
  }
}
