/* eslint-disable class-methods-use-this */
import Stack from 'src/utils/stack';

import { store } from 'src/store';
import { HistoryItem } from 'src/services/canvas/types/types-canvas';
import canvasEmitter from 'src/services/canvas/helpers/canvas-emitter';
import { setRedoCount, setUndoCount } from 'src/store/slices/slice-canvas';

import MapService from './map-service';

// ----------------------------------------------------------------------

/**
 * Manages the undo and redo history of annotations, allowing the user to revert or reapply changes to annotations.
 */
export default class AnnotationHistory {
  /**
   * Stack to keep track of executed history items for undo operations.
   */
  private historyStack: Stack<HistoryItem> = new Stack<HistoryItem>();

  /**
   * Stack to keep track of undone history items for redo operations.
   */
  private redoStack: Stack<HistoryItem> = new Stack<HistoryItem>();

  /**
   * Boolean to pause record action in case redo/undo.
   */
  private pauseRecording = false;

  /**
   * Reverses the last undone action by moving the top item from the redo stack to the history stack and applying the change.
   */
  public redo(): void {
    const res = this.reverseStacks(this.redoStack, this.historyStack);

    if (res) {
      store.dispatch(setUndoCount(store.getState().canvas.undoCount + 1));
      store.dispatch(setRedoCount(store.getState().canvas.redoCount - 1));

      canvasEmitter.emit('undo-item', { item: this.historyStack.peek() });
    }
  }

  /**
   * Reverses the last action by moving the top item from the history stack to the redo stack and undoing the change.
   */
  public undo(): void {
    const res = this.reverseStacks(this.historyStack, this.redoStack);

    if (res) {
      store.dispatch(setUndoCount(store.getState().canvas.undoCount - 1));
      store.dispatch(setRedoCount(store.getState().canvas.redoCount + 1));

      canvasEmitter.emit('redo-item', { item: this.redoStack.peek() });
    }
  }

  public getRedoItem() {
    return this.redoStack.peek();
  }

  public getUndoItem() {
    return this.historyStack.peek();
  }

  /**
   * Pushes a new history item to the history stack, recording the action taken.
   *
   * @param item - The history item representing an action performed on an annotation.
   */
  public recordAction(item: HistoryItem): void {
    if (this.pauseRecording) {
      this.pauseRecording = false;
      return;
    }

    if (item.annotation.feature?.get('performance')) {
      return;
    }

    if (!this.validateRecord(item)) {
      console.error('History item not valid to insert', item);
      // Sentry here for this bug
      return;
    }

    this.historyStack.push(item);
    store.dispatch(setUndoCount(store.getState().canvas.undoCount + 1));

    canvasEmitter.emit('undo-item', { item });

    console.info('Record action', item);
  }

  /**
   * Clear all items in the history/redo stacks in case new canvas init
   */
  public clearStacks() {
    this.historyStack.clear();
    this.redoStack.clear();
    this.pauseRecording = false;
  }

  /**
   * Reverses the action of the top item in the base stack and pushes it onto the second stack.
   *
   * @param baseStack - The stack from which to pop the last action.
   * @param secondStack - The stack to which the reversed action will be pushed.
   * @returns A boolean indicating whether the reversal was successful.
   */
  private reverseStacks(baseStack: Stack<HistoryItem>, secondStack: Stack<HistoryItem>): boolean {
    const item = baseStack.pop();

    if (!item) return false;

    const { feature } = item.annotation;

    if (!feature) return false;

    if (!feature?.get('dbid')) {
      console.warn('dbid is not set', item);

      baseStack.push(item);

      return false;
    }

    this.pauseRecording = true;

    let status = false;

    try {
      const annotationClass = MapService.getInstance().annotationClasses.find(
        (a) => a.uuid === feature.get('class_uuid') // TODO: manage properties (setter and getter with types)
      );

      if (item.after && item.action === 'create') {
        annotationClass?.interactor.deleteAnnotation(item.annotation);

        secondStack.push({
          action: 'delete',
          before: item.after,
          annotation: item.annotation,
        });

        console.info('handle undo/redo create', item);
        status = true;
      }

      if (item.after && item.before && item.action === 'update') {
        const { annotation } = item;
        annotation.feature.setGeometry(item.before);
        annotationClass?.interactor.updateAnnotationByFeature(annotation.feature, item.after);

        secondStack.push({
          action: 'update',
          before: item.after,
          after: item.before,
          annotation,
        });

        console.info('handle undo/redo update', item);
        status = true;
      }

      if (item.before && item.action === 'delete') {
        const { annotation } = item;
        annotation.feature.setGeometry(item.before);
        annotation.feature.set('dbid', undefined);
        annotationClass?.source.addFeature(annotation.feature);

        secondStack.push({
          action: 'create',
          after: item.before,
          annotation,
        });

        console.info('handle undo/redo delete', item);
        status = true;
      }

      return status;
    } catch (e) {
      console.error(e);

      this.pauseRecording = false;

      return false;
    }
  }

  private validateRecord(item: HistoryItem) {
    return (
      (item.after && item.action === 'create') ||
      (item.after && item.before && item.action === 'update') ||
      (item.before && item.action === 'delete')
    );
  }
}
