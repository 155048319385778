import { m } from 'framer-motion';

import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { Theme, SxProps } from '@mui/material/styles';

import { RouterLink } from 'src/routes/components';

import { ForbiddenIllustration } from 'src/assets/illustrations';

import Iconify from 'src/components/iconify';
import { varBounce, MotionContainer } from 'src/components/animate';

// ----------------------------------------------------------------------

interface Props {
  title?: string;
  subtitle?: string;
  enableBackLink?: boolean;
  sx?: SxProps<Theme>;
}

export default function View403({
  title = 'Permission Denied',
  subtitle = 'You do not have permission to access this page',
  enableBackLink = true,
  sx,
}: Props) {
  return (
    <MotionContainer component='section' sx={{ textAlign: 'center', height: '100%', ...sx }}>
      <m.div variants={varBounce().in}>
        <Typography variant='h3' sx={{ mb: 2 }}>
          {title}
        </Typography>
      </m.div>

      <m.div variants={varBounce().in}>
        <Typography sx={{ color: 'text.secondary' }}>{subtitle}</Typography>
      </m.div>

      <m.div variants={varBounce().in}>
        <ForbiddenIllustration sx={{ height: 260, my: { xs: 5, sm: 10 } }} />
      </m.div>

      {enableBackLink && (
        <Button
          href='/'
          size='large'
          variant='contained'
          component={RouterLink}
          endIcon={<Iconify icon='eva:arrow-circle-right-fill' />}
        >
          Homepage
        </Button>
      )}
    </MotionContainer>
  );
}
