import { useNavigate } from 'react-router-dom';

import History from 'src/routes/history';

// ----------------------------------------------------------------------

const RouterNavigateSetter = () => {
  History.navigate = useNavigate();

  return null;
};

export default RouterNavigateSetter;
