import { useEffect, useCallback } from 'react';

import EventHub from '../event-hub.service';
import { EventName, EventPayloads } from '../event-map';

// ----------------------------------------------------------------------

export default function useSubscribe<T extends EventName>(
  eventName: T,
  callback: (data: EventPayloads[T]) => void,
  once: boolean = false
) {
  const unsubscribe = useCallback(() => {
    EventHub.off(eventName, callback);
  }, [eventName, callback]);

  useEffect(() => {
    if (once) {
      EventHub.once(eventName, callback);
    } else {
      EventHub.on(eventName, callback);
    }

    return unsubscribe;
  }, [eventName, callback, unsubscribe, once]);

  return unsubscribe;
}
