// ----------------------------------------------------------------------

export const displayLogoConsoleArt = (color: string = '#fff') => {
  const charAt = `
      ...'''''...
    ':dk0KXXX0kkkxxkkdl:'.
.;kXWMMMMKl       ..coxkc
.oNMMMMMMWx.            ..
;XMMMMMMMMW0dc;,'..
;XMMMMMMMMMMMMWNNK0kdl:'.
.lXMMMMMMMMMMMMMMMMMMMWN0d:.
  ,xKWMMMMMMMMMMMMMMMMMMMMWO;
    .:ok0XNWMMMMMMMMMMMMMMMMO'
        ..,:ldO0XWMMMMMMMMMMK;
';.           ..oNMMMMMMMMWd.
.:k0xl:,..     ..lXMMMMMMWKl.
  .':okOOkkkkkOOKWMMWNKOd:.
      ..';::ccccc::;'..`;

  console.info(`%c${charAt}`, `color: ${color}`);
};
