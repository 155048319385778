import 'reflect-metadata';
import { Container } from 'inversify';

import { API_URL } from 'src/configs/config-global';

import { TYPES } from './enum-services';
import { IAuthService } from './auth/auth.interface';
import { IHttpClient, AxiosHttpClient } from './http';
import { LoggerService, ILoggerService } from './logger';
import { AmplifyAuthService } from './auth/amplify-auth.service';
import { INotificationService, EnqueueSnackbarService } from './notification';

// ----------------------------------------------------------------------

export const container = new Container();

container.bind<IAuthService>(TYPES.AuthService).to(AmplifyAuthService).inSingletonScope();
container.bind<ILoggerService>(TYPES.LoggerService).to(LoggerService).inSingletonScope();
container
  .bind<INotificationService>(TYPES.NotificationService)
  .to(EnqueueSnackbarService)
  .inSingletonScope();
container
  .bind<IHttpClient>(TYPES.HttpClient)
  .toDynamicValue(
    (ctx) =>
      new AxiosHttpClient(
        {
          baseURL: API_URL,
          headers: {
            'Content-Type': 'application/json',
          },
        },
        ctx.container.get(TYPES.AuthService),
        ctx.container.get(TYPES.LoggerService),
        ctx.container.get(TYPES.NotificationService)
      )
  )
  .inSingletonScope();
