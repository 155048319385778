import { Feature } from 'ol';
import Point from 'ol/geom/Point';
import Heatmap from 'ol/layer/Heatmap';
import Geometry from 'ol/geom/Geometry';
import VectorSource from 'ol/source/Vector';

import { convertPolygonFeaturesToCenterPoints } from 'src/utils/open-layers';

import { Annotation } from 'src/services/canvas/types/types-canvas';

// ----------------------------------------------------------------------

export default class HeatmapLayer {
  annotations: Annotation[];

  radius: number;

  blur: number;

  private _olHeatmap: Heatmap<Feature<Point>> | undefined;

  public getVisible(): boolean {
    return this._olHeatmap?.getVisible() ?? false;
  }

  public setVisible(value: boolean) {
    this._olHeatmap?.setVisible(value);
  }

  public get olHeatmap(): Heatmap<Feature<Point>> | undefined {
    return this._olHeatmap;
  }

  constructor(annotations: Annotation[], radius: number, blur: number) {
    this.annotations = annotations;
    this.radius = radius;
    this.blur = blur;
  }

  public generate() {
    const rawFeatures = this.annotations.map((annotation) => {
      const feature = annotation.feature as Feature<Geometry>;
      if (!feature.get('weight')) {
        feature.set('weight', 1);
      }
      return feature;
    });

    // TODO: IRiS-SX cleanup & use strategy pattern to implement converting All the types to points
    const features = convertPolygonFeaturesToCenterPoints(rawFeatures);

    const vectorSource = new VectorSource({
      features,
    });

    const heatmapLayer = new Heatmap({
      source: vectorSource,
      blur: this.blur,
      radius: this.radius,
      opacity: 1,
      visible: true,
      weight: (feature) => {
        const weight = feature.get('weight') as number;

        return weight;
      },
      gradient: ['#09002E', '#741F70', '#E4572E', '#F9C80E'],
    });

    return heatmapLayer;
  }
}
