import { withProfiler, withErrorBoundary } from '@sentry/react';

import { store } from 'src/store';
import { APP_ENV } from 'src/configs/config-global';
import { configSentry } from 'src/configs/config-services';

import ViewErrorFallback from 'src/sections/error/vew-error-fallback';

// ----------------------------------------------------------------------

export const withSentryWrapper = (WrappedComponent: React.ComponentType<any>) => {
  const name = WrappedComponent.displayName || WrappedComponent.name;

  return withProfiler(
    withErrorBoundary(WrappedComponent, {
      beforeCapture: (scope) => {
        scope.setFingerprint([`${name}-error-boundary`]);
        scope.setTag('error.origin', 'error_boundary');
        scope.setContext('app_state', {
          writeApiCallsPending: store.getState().app.writeApiCallsPending,
          appLoading: store.getState().app.appLoading,
        });
      },
      fallback: ({ error, componentStack, resetError }) => (
        <ViewErrorFallback error={error} componentStack={componentStack} resetError={resetError} />
      ),
      onError: (error, componentStack, eventId) => {
        if (APP_ENV === 'development') {
          console.group('🔥 App Error Boundary');
          console.error('Error:', error);
          console.error('Component Stack:', componentStack);
          console.error('Event ID:', eventId);
          console.groupEnd();
        }
      },
    }),
    {
      name,
      disabled: !configSentry.withProfiler,
    }
  );
};
