import { useMemo } from 'react';
import useSWR, { SWRConfiguration } from 'swr';

import { TYPES } from 'src/services/enum-services';
import { container } from 'src/services/container';
import { RequestType, IHttpClient } from 'src/services/http';
import { DynamicPage, DynamicActionItem } from 'src/services/api/types/types-dynamic-components';

// ----------------------------------------------------------------------

const httpClient = container.get<IHttpClient>(TYPES.HttpClient);

// ----------------------------------------------------------------------

export function useGetPage(action: DynamicActionItem) {
  const args: RequestType = { url: action.url, method: action.method };

  type Response = { page: DynamicPage };

  const options: SWRConfiguration = {
    revalidateIfStale: true,
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
    suspense: true,
  };

  const { data, isLoading, error, isValidating } = useSWR(
    args,
    httpClient.request.bind(httpClient)<Response>,
    options
  );

  const memoizedValue = useMemo(
    () => ({
      page: data?.page,
      pageLoading: isLoading,
      pageError: error,
      pageValidating: isValidating,
    }),
    [data, error, isLoading, isValidating]
  );

  return memoizedValue;
}
