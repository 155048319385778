import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { IFolder } from 'src/types/types-folder';

// ----------------------------------------------------------------------

type StateType = {
  currentActiveFolder: IFolder | null;
};

const initialState: StateType = {
  currentActiveFolder: null,
};

// ----------------------------------------------------------------------

export const slicePageFolderDetails = createSlice({
  name: 'pageFolderDetails',
  initialState,
  reducers: {
    setCurrentActiveFolder: (state, action: PayloadAction<StateType['currentActiveFolder']>) => {
      state.currentActiveFolder = action.payload;
    },
  },
});

export const { setCurrentActiveFolder } = slicePageFolderDetails.actions;

export default slicePageFolderDetails.reducer;
