import { Outlet, Navigate, createBrowserRouter } from 'react-router-dom';

import App from 'src/app';
import { PATH_AFTER_LOGIN } from 'src/configs/config-global';

import { paths } from '../paths';
import { authRoutes } from './auth';
import { mainRoutes } from './main';
import { canvasRoutes } from './canvas';
import { dashboardRoutes } from './dashboard';

// ----------------------------------------------------------------------

const router = createBrowserRouter([
  {
    element: (
      <App>
        <Outlet />
      </App>
    ),
    children: [
      // SET INDEX PAGE WITH SKIP HOME PAGE
      {
        path: '/',
        element: <Navigate to={PATH_AFTER_LOGIN} replace />,
      },

      // ----------------------------------------------------------------------

      // Auth routes
      ...authRoutes,

      // Dashboard routes
      ...dashboardRoutes,

      // Canvas routes
      ...canvasRoutes,

      // Main routes
      ...mainRoutes,

      // No match 404
      { path: '*', element: <Navigate to={paths.page404} replace /> },
    ],
  },
]);

export default router;
