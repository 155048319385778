import { forwardRef } from 'react';

import Link from '@mui/material/Link';
import Box, { BoxProps } from '@mui/material/Box';

import { RouterLink } from 'src/routes/components';

// ----------------------------------------------------------------------

export interface Props extends BoxProps {
  disabledLink?: boolean;
}

const Logo = forwardRef<HTMLDivElement, Props>(({ disabledLink = false, sx, ...other }, ref) => {
  const logo = (
    <Box
      component='img'
      // TODO: toggle between light/dark logo based on settings
      src='/logo/logo_single.svg'
      sx={{ width: 40, height: 40, cursor: 'pointer', ...sx }}
      ref={ref}
      {...other} // This will forward other props to the Box component
    />
  );

  if (disabledLink) return logo;

  return (
    <Link component={RouterLink} href='/' sx={{ display: 'contents' }}>
      {logo}
    </Link>
  );
});

export default Logo;
