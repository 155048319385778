import { useMemo } from 'react';
import { useParams as _useParams } from 'react-router-dom';

// ----------------------------------------------------------------------

export function useParams<T = Record<string, string>>() {
  const params = _useParams() as T;

  return useMemo(() => params, [params]);
}
