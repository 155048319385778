import { NavigateFunction } from 'react-router-dom';

// ----------------------------------------------------------------------

// History object that will hold the navigate function
const History = {
  navigate: null as NavigateFunction | null, // Initially null, will be set later
  push: (path: string, ...rest: any) => {
    if (History.navigate) {
      History.navigate(path, ...rest);
    } else {
      window.location.href = path;
    }
  },
};

export default History;
