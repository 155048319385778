/* eslint-disable class-methods-use-this */
import { Pixel } from 'ol/pixel';
import { debounce } from 'lodash';
import { union } from '@turf/union';
import { convex } from '@turf/convex';
import { getCenter } from 'ol/extent';
import { getDistance } from 'ol/sphere';
import { DragPan } from 'ol/interaction';
import { Coordinate } from 'ol/coordinate';
import { fromCircle } from 'ol/geom/Polygon';
import { difference } from '@turf/difference';
import { Map, Feature, MapBrowserEvent } from 'ol';
import { Circle, Polygon, Geometry } from 'ol/geom';
import booleanContains from '@turf/boolean-contains';
import { featureCollection, Feature as TurfFeature, Polygon as TurfPolygon } from '@turf/helpers';

import ModeManager from 'src/services/canvas-tools/mode-manager';
import canvasEmitter from 'src/services/canvas/helpers/canvas-emitter';
import { AmplitudeEvents, AmplitudeService } from 'src/services/amplitude';
import { configCanvasBrush, configCanvasTools } from 'src/configs/config-canvas';
import { Brush, Annotation, AnnotationMode } from 'src/services/canvas/types/types-canvas';

import MapService from './map-service';
import AnnotationClass from './annotation-class';
import {
  featureFilter,
  roundCoordinates,
  getCurrentViewArea,
  convertFeatureToGeoJson,
  convertGeoJsonToFeature,
} from './helpers/helpers';

/**
 * Manages brush interactions on the map for adding and removing annotations.
 *
 * Create (draw) mode: brushedFeature is set but brushedAnnotation is null
 * Edit (add/remove) mode: both brushedFeature and brushedAnnotation are set
 */
export default class MapBrushManager {
  private isPointerDown = false;

  private mode: 'add' | 'remove' | null = null;

  private brushedAnnotation: Annotation | null = null;

  private brushedFeature: Feature | null = null;

  private previewBrushFeature: Feature | null = null;

  private previewBrushClass: AnnotationClass | undefined = undefined;

  private debouncedUpdateAnnotation: Function;

  private circleRadius = configCanvasBrush.size;

  private unsavedChanges = 0;

  private lastDrawFeature: TurfFeature<TurfPolygon> | null = null;

  private lastCoordinate: Coordinate | null = null;

  private originalGeometry: Geometry | undefined = undefined;

  /**
   * Creates an instance of MapBrushManager.
   * @param map - The OpenLayers map instance.
   */
  constructor(private map: Map) {
    this.debouncedUpdateAnnotation = debounce(this.updateAnnotation.bind(this), 300);
  }

  /**
   * Sets the radius of the brush circle.
   * @param radius - The new radius value. Must be greater than 0.
   */
  public setCircleRadius(radius: number) {
    if (radius > 0) {
      this.circleRadius = radius;
    }
  }

  /**
   * Gets the current radius of the brush circle.
   * @returns The current circle radius.
   */
  public getCircleRadius(): number {
    return this.circleRadius;
  }

  /**
   * Gets the current radius of the brush circle.
   * @returns The current circle radius.
   */
  public getComputedRadius(): number {
    const zoom = Math.max(1, (this.map.getView().getZoom() || 1) - 10);
    const zoomFactor = 1 / zoom;
    return Math.max(2, this.circleRadius * zoomFactor);
  }

  /**
   * Adds brush event listeners to the map.
   */
  public addBrushListeners() {
    if (!this.map) return;

    this.map.on('pointerdown' as 'pointermove', this.handlePointerDown.bind(this));
    this.map.on('pointerup' as 'pointermove', this.handlePointerUp.bind(this));
    this.map.on('pointermove', this.handlePointerMove.bind(this));
  }

  /**
   * Handles the pointer down event.
   * Determines if the brush mode is active and prepares for brush interaction.
   */
  private handlePointerDown() {
    const currentMode = ModeManager.getInstance().getMode();

    if (this.isBrushMode(currentMode)) {
      this.isPointerDown = true;
      this.toggleDragPan(false);
      this.resetBrushData();
    }
  }

  /**
   * Handles the pointer up event.
   * Ends the brush interaction and re-enables map panning.
   */
  private handlePointerUp() {
    const currentMode = ModeManager.getInstance().getMode();
    this.isPointerDown = false;

    if (this.isBrushMode(currentMode)) {
      if (this.unsavedChanges > 0) {
        this.updateAnnotation();
        AmplitudeService.track(AmplitudeEvents.CanvasAnnotationModified, {
          'zoom-level': this.map?.getView()?.getZoom() || 0,
          'selected-tool': Brush.Brush,
        });
      }
      if (this.brushedFeature && !this.brushedAnnotation) {
        this.createAnnotation();
      }
      this.toggleDragPan(true);
      this.resetBrushData();
    }
  }

  /**
   * Handles the pointer move event.
   * Performs brush operations for adding or removing annotations.
   * @param event - The pointer move event.
   */
  private handlePointerMove(event: MapBrowserEvent<any>) {
    const currentMode = ModeManager.getInstance().getMode();

    if (!this.isBrushMode(currentMode)) return;

    const previewCircle = !this.isPointerDown;
    const activeClass = this.getActiveAnnotationClass();

    if (this.previewBrushFeature) {
      this.previewBrushClass?.source.removeFeature(this.previewBrushFeature);
    }

    const feature = this.createBrushFeature(event.coordinate);
    activeClass?.source.addFeature(feature);
    const isEdit = currentMode === Brush.Brush;

    if (previewCircle) {
      this.previewBrushFeature = feature;
      this.previewBrushClass = activeClass;
      return;
    }

    if (isEdit || this.brushedFeature) {
      setTimeout(() => activeClass?.source.removeFeature(feature), 20);
    }

    if (isEdit) {
      this.initializeModeAndAnnotation(event);
    } else if (!this.brushedFeature) {
      this.brushedFeature = feature;
    }

    this.applyBrush(event.coordinate, feature);

    this.lastCoordinate = event.coordinate;
    this.lastDrawFeature = convertFeatureToGeoJson(feature) as TurfFeature<TurfPolygon>;
  }

  /**
   * Creates a brush feature (circle) at the specified coordinate.
   * @param coordinate - The center coordinate of the brush circle.
   * @returns The created feature.
   */
  private createBrushFeature(coordinate: number[]) {
    const circle = new Circle(coordinate, this.getComputedRadius());
    const feature = new Feature(fromCircle(circle));
    feature.setStyle(configCanvasTools.draw.brush.style);
    feature.set('brush', true);
    return feature;
  }

  private checkForOverlappingAnnotation(coordinate: Coordinate) {
    if (this.mode !== 'add' || !this.brushedAnnotation || this.unsavedChanges > 0) return;

    const currentFeature = this.brushedAnnotation.feature ?? this.brushedFeature;
    const featuresAround = this.getFeaturesAroundCoordinate(coordinate);
    const currentFeatId = currentFeature.get('id');
    const currentFeatArea = currentFeature.get('area');
    const brushedFeature = featuresAround.find(
      (feat) => feat.get('id') !== currentFeatId && feat.get('area') < currentFeatArea
    );

    if (!brushedFeature) return;

    const brushedAnnotation = this.findBrushedAnnotation(brushedFeature);

    if (!brushedAnnotation || !brushedAnnotation?.feature) return;

    this.brushedAnnotation = brushedAnnotation;
    this.brushedFeature = this.brushedAnnotation.feature;
    this.originalGeometry = this.brushedFeature.getGeometry()?.clone();
    this.mode = 'remove';
  }

  /**
   * Initializes the brush mode and annotation based on the current state and event.
   * @param event - The pointer move event.
   */
  private initializeModeAndAnnotation(event: any) {
    if (this.mode && this.brushedAnnotation) {
      this.checkForOverlappingAnnotation(event.coordinate);
      return; // already initialized
    }

    let brushedFeature = this.getFeatureAtPixel(event.pixel);
    const notInitialized = !this.mode || !this.brushedFeature;
    const noFeatureAtPixel = !brushedFeature;
    const shouldCheckAround = notInitialized && noFeatureAtPixel;

    if (shouldCheckAround) {
      brushedFeature = this.getFeaturesAroundCoordinate(event.coordinate)?.[0];
      // as we find an annotation, we set the mode to remove because it's around.
      this.mode = brushedFeature ? 'remove' : null;
    }

    this.brushedAnnotation = this.findBrushedAnnotation(brushedFeature);
    this.brushedFeature = this.brushedAnnotation?.feature ?? null;
    this.originalGeometry = this.brushedFeature?.getGeometry()?.clone();

    if (this.mode === null) {
      this.mode = this.brushedAnnotation ? 'add' : 'remove';
    }
  }

  /**
   * Applies the brush operation (add or remove) based on the current mode and feature.
   * @param feature - The brush feature.
   */
  private applyBrush(coordinate: Coordinate, feature: Feature) {
    if (!this.brushedFeature) return;

    let polygonGeoJson = convertFeatureToGeoJson(feature);

    if (
      this.lastCoordinate &&
      this.lastDrawFeature &&
      getDistance(coordinate, this.lastCoordinate) >= this.getComputedRadius()
    ) {
      const generatedConvex = convex(featureCollection([polygonGeoJson, this.lastDrawFeature]));

      if (generatedConvex) {
        polygonGeoJson = generatedConvex;
      }
    }

    const annotationGeo = convertFeatureToGeoJson(this.brushedFeature);
    let updatedGeo: any = null;
    const isEdit = !!this.brushedAnnotation;

    if (!isEdit) {
      updatedGeo = union(featureCollection([annotationGeo, polygonGeoJson]));
    } else if (this.mode === 'add') {
      const shouldSkip =
        (!this.unsavedChanges && booleanContains(annotationGeo, polygonGeoJson)) ||
        annotationGeo.geometry.type === 'MultiPolygon';

      if (shouldSkip) return;

      // fixing "Unable to complete output ring starting at [x, y]. Last matching segment found ends at [x,y]"
      annotationGeo.geometry = roundCoordinates(annotationGeo.geometry as TurfPolygon);

      updatedGeo = union(featureCollection([annotationGeo, polygonGeoJson]));
    } else {
      updatedGeo = difference(featureCollection([annotationGeo, polygonGeoJson]));
    }

    if (updatedGeo?.geometry.type === 'MultiPolygon') {
      console.warn('MultiPolygon! not supported');
      return;
    }

    if (!updatedGeo) {
      console.warn('updatedGeo is null');
      return;
    }

    const newFeature = convertGeoJsonToFeature(updatedGeo) as Feature<Polygon>;
    this.brushedFeature.setGeometry(newFeature.getGeometry());

    if (isEdit) {
      this.debouncedUpdateAnnotation();
      this.unsavedChanges += 1;
    }
  }

  /**
   * Updates the annotation after brush modifications.
   */
  private updateAnnotation() {
    if (!this.brushedAnnotation) return;

    const annotClass = this.getActiveAnnotationClass(this.brushedAnnotation);
    annotClass?.interactor.updateAnnotationByFeature(
      this.brushedAnnotation.feature,
      this.originalGeometry
    );

    this.unsavedChanges = 0;
  }

  /**
   * Updates the annotation after brush modifications.
   */
  private createAnnotation() {
    if (!this.brushedFeature) return;

    this.getActiveAnnotationClass()?.source.removeFeature(this.brushedFeature);
    this.brushedFeature.set('brush', false);
    this.brushedFeature.set('force', true);
    this.brushedFeature?.setStyle(undefined);
    this.getActiveAnnotationClass()?.source.addFeature(this.brushedFeature);

    this.unsavedChanges = 0;
  }

  /**
   * Checks if the current mode is a brush mode (add or remove).
   * @param mode - The current annotation mode.
   * @returns True if the mode is a brush mode, otherwise false.
   */
  private isBrushMode(mode: AnnotationMode): boolean {
    return mode === Brush.Brush || mode === Brush.BrushDraw;
  }

  /**
   * Resets brush data, clearing the mode and brushed annotation.
   */
  public resetBrushData() {
    if (this.previewBrushFeature) {
      this.previewBrushClass?.source.removeFeature(this.previewBrushFeature);
    }

    this.mode = null;
    this.brushedAnnotation = null;
    this.brushedFeature = null;
    this.previewBrushFeature = null;
    this.previewBrushClass = undefined;
    this.unsavedChanges = 0;
    this.lastDrawFeature = null;
    this.lastCoordinate = null;
    this.originalGeometry = undefined;
  }

  public updatePreviewBrush() {
    if (!this.previewBrushFeature) return;

    const oldGeometry = this.previewBrushFeature.getGeometry() as Polygon;
    const circle = new Circle(getCenter(oldGeometry.getExtent()), this.getComputedRadius());
    this.previewBrushFeature.setGeometry(circle);
  }

  /**
   * Gets the active annotation class from the store.
   * @returns The active annotation class.
   */
  private getActiveAnnotationClass(brushedAnnotation: Annotation | null = null) {
    const mapService = MapService.getInstance();
    const activeClass = mapService.getActiveAnnotationClass();

    if (!brushedAnnotation) return activeClass;

    const classUuid = brushedAnnotation.feature?.get('class_uuid');

    return mapService.annotationClasses.find((ac) => ac.uuid === classUuid) || activeClass;
  }

  /**
   * Toggles the DragPan interaction on the map.
   * @param enable - True to enable DragPan, false to disable.
   */
  private toggleDragPan(enable: boolean) {
    this.map.getInteractions().forEach((interaction) => {
      if (interaction instanceof DragPan) {
        interaction.setActive(enable);
      }
    });
  }

  /**
   * Gets the feature at the pointer event's pixel that is not a brush feature.
   * @param event - The pointer event.
   * @returns The found feature, or undefined if none found.
   */
  private getFeatureAtPixel(pixel: Pixel, customMapArea: number = 0) {
    const mapArea = customMapArea || getCurrentViewArea(this.map);

    return this.map
      .getFeaturesAtPixel(pixel)
      .filter(
        (f) => f.getGeometry()?.getType() === 'Polygon' && featureFilter(f, this.map, mapArea)
      )
      .sort((f1, f2) => f1.get('area') - f2.get('area'))?.[0] as Feature<Geometry> | undefined;
  }

  private getFeaturesAroundCoordinate(coordinate: Coordinate) {
    const radius = this.getComputedRadius();
    const circle = new Circle(coordinate, radius);
    const geometry = fromCircle(circle);
    const aroundCoordinates = geometry.getCoordinates()[0];
    const mapArea = getCurrentViewArea(this.map);
    const features = [];
    const featuresIds = new Set();

    for (let i = 0; i < aroundCoordinates.length; i += 1) {
      const pixel = this.map.getPixelFromCoordinate(aroundCoordinates[i]);
      const coordFeat = this.getFeatureAtPixel(pixel, mapArea);
      const alreadyAdded = featuresIds.has(coordFeat?.get('id'));

      if (coordFeat && !alreadyAdded) {
        features.push(coordFeat);
        featuresIds.add(coordFeat.get('id'));
      }
    }

    return features;
  }

  /**
   * Finds the annotation associated with the given feature.
   * @param feature - The feature to find the annotation for.
   * @returns The found annotation, or null if none found.
   */
  private findBrushedAnnotation(feature: Feature | undefined) {
    if (!feature) return null;

    const selectedAnnotationClass = MapService.getInstance().annotationClasses.find(
      (annotationClass) => annotationClass.uuid === feature.get('class_uuid') // TODO: manage properties (setter and getter with types)
    );

    return (
      selectedAnnotationClass?.annotationsManager.findAnnotationByFeatureId(feature.get('id')) ??
      null
    );
  }

  reset() {
    this.circleRadius = configCanvasBrush.size;
    canvasEmitter.emit('BrushManager-reset');
  }
}
