import { useMemo, useState, useEffect, useCallback } from 'react';

import { displayLogoConsoleArt } from 'src/utils/console-art';

import { primary } from 'src/theme/palette';
import { useAppDispatch } from 'src/store/hooks';
import { getInit } from 'src/services/api/apis-app';
import { setMeAction } from 'src/store/slices/slice-app';
import { APP_ENV, APP_MODE } from 'src/configs/config-global';

// ----------------------------------------------------------------------

export function useAppInit() {
  const dispatch = useAppDispatch();
  const [error, setError] = useState<Error>();
  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    console.info(`🚀 App is running in ${APP_ENV} environment ~ and ${APP_MODE} mode.`);
    displayLogoConsoleArt(primary.main);
  }, []);

  const initialize = useCallback(async () => {
    setIsLoading(true);

    try {
      const { actions } = await getInit();
      dispatch(setMeAction(actions.me));

      setError(undefined);
    } catch (err) {
      setError(err);
    } finally {
      setIsLoading(false);
    }
  }, [dispatch]);

  useEffect(() => {
    initialize();
  }, [initialize]);

  const memoizedValue = useMemo(() => ({ isLoading, error }), [isLoading, error]);

  return memoizedValue;
}
