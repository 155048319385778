import { configureStore } from '@reduxjs/toolkit';

import appReducer from './slices/slice-app';
import canvasReducer from './slices/slice-canvas';
import pageCanvasReducer from './slices/slice-page-canvas';
import pageFolderDetailsReducer from './slices/slice-page-folder-details';

// ----------------------------------------------------------------------

export const store = configureStore({
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),

  reducer: {
    app: appReducer,
    canvas: canvasReducer,
    pageCanvas: pageCanvasReducer,
    pageFolderDetails: pageFolderDetailsReducer,
  },
});
